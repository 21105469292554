import { Skeleton } from 'primereact/skeleton'
import React from 'react'

const OrderDetailsSkeleton = () => {
    return (
        <>
            <div className="flex w-full justify-between md:flex-row flex-col order-detail">
                <div className="py-2"></div>
                <div className="flex flex-col gap-4 w-full mb-2 leftside">
                    <Skeleton className='rounded-xl' width="44rem" height="12rem"></Skeleton>
                    <Skeleton className='rounded-xl' width="44rem" height="12rem"></Skeleton>
                </div>
                <div className="mt-1 ml-5 totalwrap w-full mb-2">
                    <Skeleton className='rounded-xl' width="27rem" height="15rem"></Skeleton>
                </div>
            </div>
        </>
    )
}

export default OrderDetailsSkeleton
