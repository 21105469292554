import Navbar from "./Navbar/Navbar";


const Header = () =>{
    return <header className="header">
    <Navbar/>
    {/* <SubNavbar/> */}
  </header>;
}

export default Header