export const CLIENT_ROUTES = {
    CLIENT_BASE_URL: 'http://localhost:3000/',
    HOME: '/',
    ORDERS: '/profile/orders',
    WISHLIST: '/wishlist',
    CART: '/cart',
    AUTH: {
        LOGIN: '/auth/login',
        REGISTER: '/auth/signup',
        EMAIL_LOGIN: "/auth/login/email",
        VERIFY_OTP: '/auth/login/verify-otp',
        VERIFY_EMAIL: "/auth/login/email/verify-email",
        CHANGE_PASSWORD: "/auth/change-password",
        FORGOT_PASSWORD: "/auth/forgot-password"
    },
    PRODUCT: {
        PRODUCT_LIST: '/products',
        PRODUCT_DETAILS: 'product/:id'
    },
    PROFILE: {
        PROFILE_PAGE: "/profile/my-profile", 
    },
    ADDRESS:{
        ADD_ADDRESS:"/profile/addresses",
        ADDRESS_FORM:"/profile/addresses/address-new"
    },
    STATIC:{
        HELP_AND_SUPPORT:'/help-and-support',
        PRIVACY_POLICY:'/help-and-support/privacy-policy',
        TERMS_AND_CONDITION:'/help-and-support/terms-and-condition',
        RETURN_POLICIES:'/help-and-support/return-policy',
        HELP_AND_SUPPORT_CONTACT_US:'/help-and-support/contact-us',
        FAQ:'/faq',
        DELIVERY_DETAILS:'/delivery',
        OUR_STORY:'/our-story',
        ABOUT:'/about'        
    }
}