import React, { createContext, useState, useContext, useEffect } from 'react';

// Create a Context
const GeoLocationContext = createContext();

// Create a Provider Component
export const GeoLocationProvider = ({ children }) => {
  const [locationData, setLocationData] = useState(() => {
    // Load from localStorage if it exists
    return {
      pincode: localStorage.getItem('pincode') || '',
      addressId: localStorage.getItem('addressId') || '',
    };
  });

  // Function to update pincode and addressId
  const updateLocationData = (newData) => {
    const updatedData = { ...locationData, ...newData };
    setLocationData(updatedData);
    if (newData.pincode) localStorage.setItem('pincode', newData.pincode);
    if (newData.addressId) localStorage.setItem('addressId', newData.addressId);
  };

  return (
    <GeoLocationContext.Provider value={{ locationData, updateLocationData }}>
      {children}
    </GeoLocationContext.Provider>
  );
};

// Hook to use the Location Context
export const useGeoLocation = () => useContext(GeoLocationContext);
