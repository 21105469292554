import React, { useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import styles from "./SearchBar.module.css";
import { getCatalogList } from "../../api/lib/product";
import { ENUM, SORTING } from "../../constant/enum";

const SearchBar = () => {
  const [searchKey, setSearchKey] = useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  // Debounced API call
  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  };
  const fetchSearchResults = async (query) => {
    setIsLoading(true);
    try {
      const response = await getCatalogList({
        page: 1,
        limit: 5,
        sortedBy: ENUM.SORT_BY.CREATED_DATE, // 'ASC' or 'DESC'
        sortOrder: SORTING.ASCENDING, // 'price' or other
        search: query,
      });
      setSearchResults(response.data.data.list);
    } catch (error) {
      console.error("Error Fetching Data", error);
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedFetch = useCallback(debounce(fetchSearchResults, 500), []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchKey(value);
    if (value.trim()?.trim()?.length > 3) {
      debouncedFetch(value);
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (searchKey?.trim()?.length > 3) {
      navigate(`/products?search=${encodeURIComponent(searchKey)}`);
    }
  };

  const handleResultClick = (result) => {
    navigate(`/product/${result.sku}`);
    setSearchResults([]);
    setSearchKey("");
  };

  const hideDropdown = () => {
    setSearchResults([]);
  };

  return (
    <>
      <div className="relative" onBlur={hideDropdown}>
        <form className={styles.container} onSubmit={handleFormSubmit}>
          <div className="relative">
            <div className="absolute top-0 bottom-0 w-6 h-6 my-auto text-gray left-3">
              <Icon icon="bitcoin-icons:search-filled" width="24" height="24" />
            </div>
            <input
              type="text"
              size={50}
              placeholder="Search for products"
              className="w-full py-2 pl-12 pr-4 text-gray border rounded-3xl outline-none bg-white focus:bg-white focus:border-blue-light"
              value={searchKey}
              onChange={handleInputChange}
            />
          </div>
        </form>
        {isLoading && (
          <div className="absolute left-0 right-0 bg-white p-2 text-gray-500">
            Loading...
          </div>
        )}
        {searchResults.length > 0 && (
          <div
            className="absolute left-0 right-0 bg-white border rounded-md mt-2 max-h-60 overflow-auto shadow-lg z-10"
            onMouseDown={(e) => e.preventDefault()}
          >
            {searchResults.map((result) => (
              <div
                key={result.sku}
                className="p-2 hover:bg-gray-100 cursor-pointer"
                onClick={() => {
                  handleResultClick(result);
                }}
              >
                {result.name}
              </div>
            ))}
            <br></br>
          </div>
        )}
      </div>
    </>
  );
};

export default SearchBar;
