import { Container, Grid } from '@mui/material';
import { Skeleton } from 'primereact/skeleton';

const ProductPageShimmer = () => {
    return (
        <>
            <Container
                maxWidth={"lg"}
                className="product-detail-page py-4 relative overflow-hidden"
            >
                <Grid container className="product-detail   ">
                    <div className='h-24'></div>
                    <Grid className="flex imageslide" item xs={6}>
                        <Grid container display={"flex"}>
                            <Grid
                                item
                                xs={12}
                                className="flex justify-center product-images"
                            >
                                <Skeleton className='rounded-xl' width="450px" height="520px"></Skeleton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4.5} className="product-text-wrap">
                        <div
                            id="info-container"
                            className="flex product-detail-text flex-col gap-3"
                        >
                            <div id='name-and-sku'>
                                <Skeleton className='rounded-xl' width="35rem" height="3rem"></Skeleton>
                            </div>
                            <div id="price-details" className="flex flex-col">
                                <div className="flex items-center">
                                    <div id='price'>
                                        <Skeleton className='rounded-xl' width="13rem" height="3rem"></Skeleton>
                                    </div>
                                </div>
                            </div>
                            <div
                                id="quantity-and-add-to-cart-container"
                                className="flex items-center gap-4"
                            >
                                <Skeleton className='rounded-xl' width="7rem" height="3rem"></Skeleton>
                                <Skeleton className='rounded-xl' width="9rem" height="3rem"></Skeleton>

                            </div>
                            <>
                                <div id="add-to-cart-button" className="addButton">

                                </div>
                            </>
                        </div>
                        <div id="product-container" className="mt-4 flex flex-col">
                            <Skeleton className='rounded-xl' width="35rem" height="15rem"></Skeleton>
                        </div>
                    </Grid>
                </Grid >
            </Container>
        </>
    );
}

export default ProductPageShimmer;
