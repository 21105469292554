import { useEffect, useState } from "react";
import { useAsyncError, useParams } from "react-router-dom";
import { getOrderById } from "../../api/lib/cart";
import { ENUM } from "../../constant/enum";
import testImage from "../../assets/icons/pushkar_foods_thumbnail.png";
import { Grid } from "@mui/material";
import OrderDetailsSkeleton from "../../components/shimmer-ui-components/OrderPage/OrderDetailsSkeleton";

const OrderDetails = () => {
  const { orderId } = useParams(); // Get orderId from the URL
  const [orderDetails, setOrderDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Fetch order details using the orderId
    if (orderId) fetchOrderDetails();
  }, [orderId]);

  const fetchOrderDetails = async () => {
    try {
      setIsLoading(true);
      const response = await getOrderById(orderId);
      // console.log("response", response);
      setOrderDetails(response.data.data);
    } catch (error) {
      // console.error("Error fetching order details:", error);
    }
    finally {
      setIsLoading(false);
    }
  };
  const getStatusName = (code) => {
    switch (code) {
      case ENUM.OFFER_STATUS.PROCESSING:
        return "Processing";
      case ENUM.OFFER_STATUS.SUCCESS:
        return "Success";
      case ENUM.OFFER_STATUS.FAILED:
        return "Failed";
      default:
        return "Unknown Status";
    }
  };

  const formatDate = (dateString: string | undefined | null): string => {
    try {
      if (!dateString) {
        // console.error("Invalid dateString:", dateString);
        return "Invalid Date";
      }

      const date = new Date(dateString);

      if (isNaN(date.getTime())) {
        // console.error("Invalid Date object created:", dateString);
        return "Invalid Date";
      }

      const options: Intl.DateTimeFormatOptions = {
        day: "numeric",
        month: "short",
        year: "numeric",
      };
      return new Intl.DateTimeFormat("en-US", options).format(date);
    } catch (error) {
      // console.log("error formatting data", error);
    }
  };

  return (
    <>
      {isLoading ? <OrderDetailsSkeleton /> :
        (<>
          <div className="flex w-full justify-between md:flex-row flex-col order-detail">
            <div className="py-2"></div>
            <div className="bg-[#FFFFFF] border rounded-3xl border-[#EFEFF1] p-4 md:w-[60%] w-full mb-2 leftside">
              <p className="md:text-3xl text-lg color-lightblack font-bold">
                Order Details
              </p>
              <div className="order-card border-b border-[#EFEFF1] md:pb-4 md:mb-4 pb-2 mb-2">
                <div className="detail-delivery-status">
                  <div className="status md:text-base text-[12px] color-lightblack font-medium flex items-center">
                    <span className="label">Order ID :</span> {orderDetails?.razorpayOrderId}
                  </div>
                  <div className="status md:text-base text-[12px] color-lightblack font-medium flex items-center">
                    {/* <span className="dot dot-Deliverd dot-Cancelled dot-Return"></span>{" "} */}
                    <span className="label">Order Status :</span>
                    <div className="flex items-center">
                      <span
                        className={`dot ${orderDetails?.orderStatus === ENUM.OFFER_STATUS.PROCESSING
                            ? "dot-Return"
                            : orderDetails?.orderStatus === ENUM.OFFER_STATUS.SUCCESS
                              ? "dot-Deliverd"
                              : orderDetails?.orderStatus === ENUM.OFFER_STATUS.FAILED
                                ? "dot-Cancelled"
                                : "dot-Return"
                          }`}
                      ></span>
                      {getStatusName(orderDetails?.orderStatus)}
                    </div>
                  </div>
                  <div className="date color-lightblack font-medium md:text-base text-[12px] flex items-center">
                    <span className="label">Ordered On :</span>{" "}
                    {formatDate(orderDetails?.createdAt)}
                  </div>
                  <div className="status md:text-base text-[12px] color-lightblack font-medium flex items-center">
                    {/* <span className="dot dot-Deliverd dot-Cancelled dot-Return"></span>{" "} */}
                    <span className="label">Delivery Status :</span>{" "}
                    <div className="flex items-center">
                      <span
                        className={`dot ${orderDetails?.deliveryStatus ===
                            ENUM.OFFER_STATUS.PROCESSING
                            ? "dot-Return"
                            : orderDetails?.deliveryStatus ===
                              ENUM.OFFER_STATUS.SUCCESS
                              ? "dot-Deliverd"
                              : orderDetails?.deliveryStatus ===
                                ENUM.OFFER_STATUS.FAILED
                                ? "dot-Cancelled"
                                : "dot-Return"
                          }`}
                      ></span>
                      {getStatusName(orderDetails?.deliveryStatus)}
                    </div>
                  </div>
                  <div className="date color-lightblack font-medium md:text-base text-[12px] flex items-center">
                    <span className="label">Shipping Details :</span>{" "}
                    {orderDetails?.shippingDetails?.pincode}
                  </div>
                </div>
              </div>
              {orderDetails?.items?.map((item) => (
                <div
                  key={item.sku}
                  className="orderitem flex items-start justify-start gap-2"
                >
                  <div id="productimage"
                    className="flex justify-center md:p-2 p-1 md:h-[130px] md:w-[20%] w-[25%] h-[110px] border rounded-xl border-[#EFEFF1]">
                    <img
                      className="w-auto h-auto"
                      src={
                        item?.mediaUrls && item?.mediaUrls?.length > 0
                          ? item.mediaUrls[0].resizedUrls?.[2]?.url
                          : testImage
                      }
                    />
                  </div>
                  <div className="ml-3 md:w-[80%] w-[75%]">
                    <div className="md:text-base text-xs font-semibold text-black capitalize">
                      {item.name}
                    </div>

                    <div className="pricewrapper flex">
                      <div className="text-black md:text-sm text-xs font-semibold">
                        ₹{item?.finalPrice}
                      </div>
                      {item?.finalPrice < item?.productPrice ? (
                        <>
                          <div className=" text-xs text-gray-dark line-through  pl-1">
                            ₹{item?.productPrice}
                          </div>
                          <div className="text-xs text-secondary uppercase">
                            (5% off)
                          </div>{" "}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="md:text-sm text-xs font-medium mb-1">
                      HSN : {item.hsn.value}
                    </div>

                    <div className="md:text-sm text-xs font-medium mb-1">
                      GST {item.hsn.tax.igst}% (₹
                      {(item?.finalPrice * item.hsn.tax.igst) / 120})
                    </div>

                    <div className="text-black md:text-xl text-xs font-semibold">
                      Total ₹{item?.finalPrice}
                    </div>
                  </div>

                </div>
              ))}
            </div>
            <div className="mt-1 totalwrap md:w-[38%] w-full mb-2">
              <div className="items-totalprice bg-[#FFFFFF] border border-[#EFEFF1] rounded-3xl px-6 py-4 mb-2">
                <p className="md:text-3xl text-lg color-lightblack font-bold mt-4">
                  Total
                </p>
                <div className="item-price">
                  <span className="label">Subtotal</span>
                  <span className="price">
                    ₹{(orderDetails?.cartTotal?.cartTotal || 0).toFixed(2)}
                  </span>
                </div>
                <div className="item-price">
                  <span className="label">GST</span>
                  <span className="price">
                    ₹{(orderDetails?.cartTotal?.taxTotal || 0).toFixed(2)}
                  </span>
                </div>
                <div className="item-price">
                  <span className="label">Shipping</span>
                  <span className="price">
                    ₹{(orderDetails?.cartTotal?.shippingTotal || 0).toFixed(2)}
                  </span>
                </div>
                <div className="item-price">
                  <span className="label font-bold">TOTAL</span>
                  <span className="price font-bold">
                    ₹{(orderDetails?.cartTotal?.cartTotal || 0).toFixed(2)}
                  </span>
                </div>
                <div className="item-price">
                  <span className="label">Your Savings</span>
                  <span className="price">
                    ₹{(orderDetails?.cartTotal?.discountTotal || 0).toFixed(2)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>)}
    </>
  );
};

export default OrderDetails;
