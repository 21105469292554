import Container from "@mui/material/Container";
import { Grid, Box, Typography } from "@mui/material";
import WishlistCard from "../../components/wishlist-card/WishlistCard";
import { useWishlist } from "../../context/WishlistContext";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getAllWishlist } from "../../api/lib/wishlist";
import { useAuth } from "../../context/AuthContext";
// import { setAuthorizationHeader } from "../../api/axiosClient";
import { Icon } from "@iconify/react";

const WishlistPage = () => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // Set loading to true initially
  const { wishlistProducts, setWishlistProducts } = useWishlist();
  const { token } = useAuth();

  useEffect(() => {
    const fetchWishlist = async () => {
      if (token) {
        try {
          setLoading(true)
          const response = await getAllWishlist({ page: 1, limit: 5 });
          setWishlistProducts(response.data.data.data);
        } catch (error) {
          // console.error("Error fetching wishlist", error);
        } finally {
          setLoading(false);
        }
      }
      else{
        setLoading(false);
        // console.log('Token not found')
      }
    };

    fetchWishlist();
    return () => {
      setWishlistProducts([]);
    };
  }, [token, setWishlistProducts]);


  return (
    <Container maxWidth={false} sx={{ maxWidth: "70%", minHeight: "100vh" }}>
      {loading ?
        (<div className="flex justify-center items-center h-[50vh]">
          <Icon icon="line-md:loading-twotone-loop" width="50" height="50" className="text-primary" />
          Loading...
        </div>)
        : <>
          <Box
            className="flex items-center text-primary"
            component={Link}
            to={`/products`}
            marginTop={2.5}
          >
            <Icon icon="ri:arrow-left-s-line" width="1.2rem" height="1.2rem" className="text-primary" />
            <Typography variant="subtitle2" sx={{ textDecoration: "none" }}>
              Back to my account
            </Typography>
          </Box>
          <div className="text-xl font-bold mb-3 mt-3 ml-2 pb-1 border-solid border-b-[3px] border-primary inline-block">
            My Wishlist:{" "}
          </div>
          {wishlistProducts?.length === 0 ? (
            <div className="mt-3 flex justify-center items-center flex-col" >
              <div className="text-xl font-bold">You don't have any products in your wishlist!</div>
              <button onClick={() => { navigate("/products") }} className="mt-2 px-3 py-1 text-md bg-primary text-white rounded-sm">Browse Products</button>
            </div>
          ) : (
            <Grid container spacing={4} display={"flex"}>
              {wishlistProducts?.map((product, index) => (
                <WishlistCard key={index} product={product} />
              ))}
            </Grid>
          )}
        </>}

    </Container>
  );
};

export default WishlistPage;
