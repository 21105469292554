import { SET_BANNERS, SET_BEST_SELLERS, SET_BRANDS, SET_CONTACT_INFO, SET_GROWTH, SET_SOCIAL_INFO } from "../actions/dataActions";

const initialState = {
  brands: [],
  growth: {},
  contactInfo: {},
  socialInfo: {},
  bestSellers: [],
  banners: [],
};

const dataReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_BRANDS:
      return {
        ...state,
        brands: action.payload,
      };
    case SET_GROWTH:
      return {
        ...state,
        growth: action.payload,
      };
    case SET_CONTACT_INFO:
      return {
        ...state,
        contactInfo: action.payload,
      };
    case SET_SOCIAL_INFO:
      return {
        ...state,
        socialInfo: action.payload,
      };
    case SET_BEST_SELLERS:
      return {
        ...state,
        bestSellers: action.payload,
      };
    case SET_BANNERS:  // Added case to handle SET_BANNERS
      return {
        ...state,
        banners: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;