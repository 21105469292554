import { Icon } from "@iconify/react";
import { Grid } from "@mui/material";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { getRequiredProductFromCatalog } from "../../api/lib/product";
import testImage from "../../assets/icons/pushkar_foods_thumbnail.png";
import { IMAGE_HUB } from "../../constant/image_hub";
import { useCart } from "../../context/CartContext";

const MAX_TITLE_LENGTH = isMobile ? 20 : 16;

const ProductCard = ({ product }) => {
  const toast = useRef(null);
  const { cartCount, addToCart, updateCartSize } = useCart();
  // const { addToWishlist, removeFromWishlist } = useWishlist();
  // const [isWishlist, setIsWishlist] = useState(product?.isWishlist);
  const [imageSrc, setImageSrc] = useState(product.mediaUrls[0]);
  const [quantity, setQuantity] = useState(1);
  const [productDetails, setProductDetails] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setProductDetails(product);
  }, [product]);

  useEffect(() => {
    setQuantity(productDetails?.quantity || 1);
  }, [productDetails]);

  const fetchProduct = async () => {
    try {
      const response = await getRequiredProductFromCatalog({
        sku: productDetails.sku,
      });
      setProductDetails(response.data.data);
    } catch (error) {
      console.error(`Error in fetching product?`, error);
    }
  };

  const handleIncrement = async (e) => {
    try {
      setIsLoading(true);
      e.stopPropagation();
      if (productDetails.isCartAdded) {
        await updateCartSize({
          catalogId: productDetails._id,
          quantity: quantity + 1,
        });
        if (toast.current) {
          toast.current.show({
            severity: "success",
            summary: "Product Added",
            detail: "Successful",
            life: 3000,
          });
        }
        fetchProduct();
      } else {
        setQuantity((prevQuantity) => prevQuantity + 1);
      }
      // setIsLoading(false);
    } catch (error) {
      console.log("Error updating cart", error);
      setIsLoading(false);
      if (toast.current) {
        toast.current.show({
          severity: "error",
          summary: "Failed to add Product",
          detail: "Failed",
          life: 3000,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleDecrement = async (e) => {
    try {
      setIsLoading(true);
      e.stopPropagation();
      if (quantity > 1) {
        if (productDetails.isCartAdded) {
          await updateCartSize({
            catalogId: productDetails._id,
            quantity: quantity - 1,
          });
          if (toast.current) {
            toast.current.show({
              severity: "success",
              summary: "Product Removed",
              detail: "Successful",
              life: 3000,
            });
          }
          fetchProduct();
        } else {
          setQuantity((prevQuantity) => prevQuantity - 1);
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.log("Error updating cart", error);
      setIsLoading(false);
      if (toast.current) {
        toast.current.show({
          severity: "error",
          summary: "Failed to Remove Product",
          detail: "Failed",
          life: 3000,
        });
      }
    }
  };

  const addProductToCart = async (e) => {
    setIsLoading(true);
    e.stopPropagation();
    try {
      await addToCart({ catalogId: productDetails._id, quantity: quantity });
      fetchProduct();
      setIsLoading(false);
      if (toast.current) {
        toast.current.show({
          severity: "success",
          summary: "Product Added",
          detail: "Successful",
          life: 3000,
        });
      }
    } catch (error) {
      console.log("Error updating cart", error);
      setIsLoading(false);
      if (toast.current) {
        toast.current.show({
          severity: "error",
          summary: "Failed to add Product",
          detail: "Failed",
          life: 3000,
        });
      }
    }
  };

  const handleImageError = () => {
    setImageSrc(IMAGE_HUB.SAMPLE_PRODUCT_IMAGE);
  };

  const percentageOff = productDetails?.price
    ? Math.round(
        ((productDetails.price.mrp - productDetails.price.selling) /
          productDetails.price.mrp) *
          100
      )
    : 0;

  const navigate = useNavigate();
  const handleCardClick = () => {
    navigate(`/product/${productDetails?.sku}`);
  };

  return (
    <>
      <Toast ref={toast}></Toast>

      <Grid
        onClick={handleCardClick}
        className="cursor-pointer grid-item productcard relative overflow-hidden"
      >
        {/* {isLoading && <div className="absolute rounded-3xl top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-10">
          <span className="text-white font-semibold text-lg">
          <ProgressSpinner
                style={{ width: "50px", height: "50px" }}
                strokeWidth="8"
                fill="var(--surface-ground)"
                animationDuration=".5s"
              />
          </span>
        </div>} */}

        <div className="p-2 rounded-3xl bg-white">
          <div id="product-image" className="flex justify-center py-4">
            <img
              className="w-48 h-48"
              src={
                productDetails?.mediaUrls &&
                productDetails?.mediaUrls?.length > 0
                  ? productDetails.mediaUrls[0].resizedUrls?.[2]?.url
                  : testImage
              }
              alt="Product"
            />
          </div>

          <div
            id="product-info"
            className="product-info infowrapper card-textbg p-4 md:rounded-3xl rounded-lg"
          >
            <div className="md:text-xl text-xs font-semibold text-black capitalize ptitle">
              {productDetails?.name}
            </div>

            <div className="flex mt-0.5 items-center">
              <div className="text-black font-bold md:text-xl text-xl font-semibold">
                ₹{productDetails?.price?.selling}
              </div>

              {productDetails?.price?.selling < productDetails?.price?.mrp ? (
                <>
                  <div className="ml-2 text-sm text-gray-dark line-through  decoration-[1.5px]">
                    ₹{productDetails?.price?.mrp}
                  </div>
                  <div className="md:text-sm text-xs ml-3 text-secondary uppercase">
                    ({percentageOff}% off)
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>

            {productDetails?.stock?.inStock ? (
              <div
                id="counter-add-container"
                className="flex items-center justify-between mt-4"
              >
                <div id="quantity-counter">
                  <form className="max-w-xs mx-auto">
                    {productDetails.isCartAdded ? 
                    <div className="flex items-center bg-white countBtn">
                      <button
                        disabled={isLoading}
                        type="button"
                        id="decrement-button"
                        onClick={(e) => handleDecrement(e)}
                        className="flex items-center justify-center bg-white"
                      >
                        <span className="text-black">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="4"
                            fill="none"
                            viewBox="0 0 20 4"
                          >
                            <path
                              fill="#343539"
                              d="M17.737 3.886H2.444c-1.008 0-1.821-.728-1.821-1.63 0-.902.813-1.63 1.82-1.63h15.294c1.008 0 1.82.728 1.82 1.63 0 .902-.812 1.63-1.82 1.63z"
                            ></path>
                          </svg>
                        </span>
                      </button>
                      <div className="flex items-center justify-center text-black md:text-base text-[10px] font-semibold px-2">
                        {quantity}
                      </div>
                      <button
                        disabled={isLoading}
                        type="button"
                        id="increment-button"
                        onClick={(e) => handleIncrement(e)}
                        className="flex items-center justify-center bg-white"
                      >
                        <span className="text-black">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="19"
                            height="19"
                            fill="none"
                            viewBox="0 0 19 19"
                          >
                            <path
                              fill="#343539"
                              d="M17.338 7.723H11.35V1.971c0-.86-.727-1.559-1.623-1.559s-1.623.698-1.623 1.56v5.751H2.116c-.896 0-1.623.698-1.623 1.56 0 .86.727 1.559 1.623 1.559h5.988v5.752c0 .861.727 1.56 1.623 1.56s1.623-.699 1.623-1.56v-5.752h5.988c.896 0 1.623-.698 1.623-1.56 0-.86-.727-1.559-1.623-1.559z"
                            ></path>
                          </svg>
                        </span>
                      </button>
                    </div>
                    : ''}
                  </form>
                </div>
                <>
                  {productDetails.isCartAdded ? (
                    <div
                      id="add-to-cart-button"
                      // onClick={(e) => addToCart(e)}
                      className="addButton"
                    >
                      {isLoading ? (
                        <div
                          className="flex items-center add-btn"
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                        >
                          <Icon icon="svg-spinners:ring-resize" className="" />
                        </div>
                      ) : (
                        <button className="flex items-center add-btn btn-added">
                          Added
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="10"
                            fill="none"
                            viewBox="0 0 14 10"
                            className="ml-2"
                          >
                            <path
                              fill="#fff"
                              d="M13.7.294a.98.98 0 0 0-1.4 0L4.8 7.647l-3.1-3.04a.98.98 0 0 0-1.4 0C-.1 5-.1 5.589.3 5.98l3.8 3.726c.2.196.4.294.7.294s.5-.098.7-.294l8.2-8.04c.4-.391.4-.98 0-1.372"
                            ></path>
                          </svg>
                        </button>
                      )}
                    </div>
                  ) : (
                    <div
                      id="add-to-cart-button"
                      onClick={(e) => addProductToCart(e)}
                      className="addButton"
                    >
                      <button
                        className="flex items-center add-btn"
                        disabled={isLoading}
                      >
                        Add
                        <svg
                          className="ml-2"
                          xmlns="http://www.w3.org/2000/svg"
                          width="19"
                          height="18"
                          fill="none"
                          viewBox="0 0 19 18"
                        >
                          <path
                            fill="#fff"
                            d="M17.29 7.311h-5.987V1.56C11.303.7 10.576 0 9.68 0S8.057.698 8.057 1.56V7.31H2.069c-.896 0-1.623.698-1.623 1.56 0 .86.727 1.559 1.623 1.559h5.988v5.752c0 .861.727 1.56 1.623 1.56s1.623-.699 1.623-1.56V10.43h5.988c.896 0 1.623-.698 1.623-1.56 0-.86-.727-1.559-1.623-1.559z"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  )}
                </>
              </div>
            ) : (
              <div
                id="counter-add-container"
                className="flex items-center justify-between mt-4"
              >
                Sorry, We are out of stock!!
              </div>
            )}
          </div>
        </div>
      </Grid>
    </>
  );
};

export default ProductCard;
