import { Box, Grid, Paper, Divider } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import constant from "../../constant/constants";
import { useCart } from "../../context/CartContext";
import DropdownMenu from "../../utility/dropdown/dropdown-menu/DropdownMenu";
import DropdownItems from "../../utility/dropdown/dropdown-items/DropdownItems";
import { useWishlist } from "../../context/WishlistContext";
import testImage from "../../assets/product.png";
import { getRequiredProductFromCatalog } from "../../api/lib/product";
import { Toast } from "primereact/toast";
import { ProgressSpinner } from "primereact/progressspinner";
import { Icon } from "@iconify/react";
const CartCard = ({ product, fetchCartItems }) => {
  const navigate = useNavigate();
  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { removeCartItem, updateCartQuantity, updateCartSize } = useCart();
  const { addToWishlist, items } = useWishlist();
  
  const handleIncrement = async (e) => {
    try {
      setIsLoading(true);
      e.stopPropagation();
      await updateCartSize({
        catalogId: product.catalogId,
        quantity: product?.quantity + 1,
      });
      if (toast.current) {
        toast.current.show({
          severity: "success",
          summary: "Product Added",
          detail: "Successful",
          life: 3000,
        });
      }
      fetchCartItems();
      setIsLoading(false);
    } catch (error) {
      console.log("Error updating cart", error);
      setIsLoading(false);
      if (toast.current) {
        toast.current.show({
          severity: "error",
          summary: "Failed to add Product",
          detail: "Failed",
          life: 3000,
        });
      }
    }
  };

  const handleDecrement = async (e) => {
    try {
      setIsLoading(true);
      e.stopPropagation();
      if (product?.quantity > 1) {
        await updateCartSize({
          catalogId: product.catalogId,
          quantity: product?.quantity - 1,
        });
        if (toast.current) {
          toast.current.show({
            severity: "success",
            summary: "Product Removed",
            detail: "Successful",
            life: 3000,
          });
        }
        fetchCartItems();
      }
      setIsLoading(false);
    } catch (error) {
      console.log("Error updating cart", error);
      setIsLoading(false);
      if (toast.current) {
        toast.current.show({
          severity: "error",
          summary: "Failed to Remove Product",
          detail: "Failed",
          life: 3000,
        });
      }
    }
  };

  const handleRemoveFromCart = async () => {
    try {
      const response = await removeCartItem(product.catalogId);
      if (toast.current) {
        toast.current.show({
          severity: "success",
          summary: "Product deleted from cart",
          detail: "Successful",
          life: 3000,
        });
      }
      fetchCartItems();
      if (toast.current) {
        toast.current.show({
          severity: "success",
          summary: "Product deleted from cart",
          detail: "Successful",
          life: 3000,
        });
      }
    } catch (error) {
      if (toast.current) {
        toast.current.show({
          severity: "error",
          summary: "Failed to remove Product cart",
          detail: "Error",
          life: 3000,
        });
      }
      console.error("error :", error);
    }
  };

  // const handleMoveToWishlist = () => {
  //   try {
  //     // addToWishlist(product, findWishlistItem);
  //     removeCartItem(product.id);
  //   } catch (error) {
  //     console.error("error :", error);
  //   }
  // };

  const handleCardClick = () => {
    try {
      navigate(`/product/${product.catalogId}`);
    } catch (error) {
      console.error("error :", error);
    }
  };

  const percentageOff = product?.productPrice
    ? Math.round(
      ((product.productPrice - product.finalPrice) / product.productPrice) * 100
    )
    : 0;

  return (
    <>
      <Toast ref={toast}></Toast>

      {/* <tr>
        <td>
          <div className="flex items-center">
            <div
              id="productimage"
              className="flex justify-center md:p-2 p-1 md:h-[74px] md:w-[75px] w-[54px] h-[54px] border rounded-xl border-[#EFEFF1]"
            >
              <img
                className="w-auto h-auto"
                src={
                  product?.mediaUrls && product?.mediaUrls?.length > 0
                    ? product.mediaUrls[0].resizedUrls?.[2]?.url
                    : testImage
                }
                alt="Product"
              />
            </div>
            <div className="ml-3">
              <div className="md:text-base text-xs font-semibold text-black capitalize">
                {product?.name || "-"}
              </div>
              <div className="md:text-sm text-xs font-medium mb-1">
                HSN : {product.hsn.value}
              </div>
              <div className="md:text-sm text-xs font-medium mb-1">
                GST {product.hsn.tax.igst}% (₹
                {(product?.finalPrice * product.hsn.tax.igst) / 100})
              </div>
            </div>
          </div>
        </td>
        <td> */}
          {/* {isLoading ? (
              <div className=" inset-0 bg-white bg-opacity-60 flex items-center justify-center z-10 w-36 h-14">
                <ProgressSpinner
                  style={{ width: "20px", height: "20px" }}
                  strokeWidth="8"
                  fill="var(--surface-ground)"
                  animationDuration=".5s"
                /> */}
          {/* <form className="max-w-[92px] wrapcount">
            <div className="flex items-center bg-white countBtn">
              <button
                type="button"
                id="decrement-button"
                className="flex items-center justify-center bg-white"
                onClick={handleDecrement}
                disabled={isLoading}
              >
                <span className="text-black">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9"
                    height="4"
                    fill="none"
                    viewBox="0 0 9 4"
                  >
                    <path fill="#000" d="M.65 3.22V.1H8.9v3.12z"></path>
                  </svg>
                </span>
              </button>
              {
                isLoading
                  ?
                  (
                    <div className="flex items-center justify-center text-black md:text-md text-xs font-semibold px-2">
                      <Icon
                        icon="svg-spinners:ring-resize"
                        className=""
                      />
                    </div>
                  )
                  :
                  (
                    <div className="flex items-center justify-center text-black md:text-md text-xs font-semibold px-2">
                      {product?.quantity}
                    </div>
                  )
              }
              <button
                type="button"
                id="increment-button"
                className="flex items-center justify-center bg-white"
                onClick={handleIncrement}
                disabled={isLoading}
              >
                <span className="text-black">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    fill="none"
                    viewBox="0 0 15 15"
                  >
                    <path
                      fill="#000"
                      d="M6.26 14.25V.75h3.21v13.5zM.95 9V6h13.83v3z"
                    ></path>
                  </svg>
                </span>
              </button>
            </div>
          </form>
        </td>
        <td>
          <div className="flex items-center justify-between price">
            <div>
            <div className="text-black md:text-2xl text-xs font-semibold">
              ₹{product?.finalPrice}
            </div>
            {product?.finalPrice < product?.productPrice ? (
              <>
                <div className=" text-xs text-gray-dark line-through">
                  ₹{product?.productPrice}
                </div>
                <div className="text-xs text-secondary uppercase">
                  ({percentageOff}% off)
                </div>{" "}
              </>
            ) : (
              <></>
            )}
            </div>

            <button
              className={`${isLoading ? 'delete-btn ml-3 cursor-not-allowed' : 'delete-btn ml-3 cursor-pointer'}`}
              onClick={handleRemoveFromCart}
              disabled={isLoading}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="23"
                fill="none"
                viewBox="0 0 18 23"
              >
                <path
                  fill="#CD1318"
                  d="M15.876 7.958H2.346c-.095 0-.186-.02-.28-.028l.6 12.091a2.387 2.387 0 0 0 2.387 2.262h8.117a2.39 2.39 0 0 0 2.387-2.264l.601-12.09c-.096.009-.186.03-.283.03M6.724 19.895a.796.796 0 1 1-1.592 0v-9.55a.796.796 0 0 1 1.592 0zm3.183 0a.796.796 0 1 1-1.592 0v-9.55a.796.796 0 0 1 1.592 0zm3.183 0a.796.796 0 0 1-1.591 0v-9.55a.796.796 0 1 1 1.591 0zM15.876 2.387h-2.798A2.41 2.41 0 0 0 10.69 0H7.52a2.387 2.387 0 0 0-2.388 2.387H2.347c-2.57 0-2.564 3.98 0 3.98h13.529c2.57 0 2.564-3.98 0-3.98M7.52 1.592h3.172a.807.807 0 0 1 .796.795H6.724a.796.796 0 0 1 .796-.795"
                ></path>
              </svg>
            </button>
          </div>
        </td>
      </tr> */}

      {/* new layout */}

      <div className="itemwrap flex">
        <div
          id="productimage"
          className="flex justify-center md:p-2 p-1 md:h-[130px] md:w-[20%] w-[25%] h-[110px] border rounded-xl border-[#EFEFF1]"
        >
          <img
            className="w-auto h-auto"
            src={
              product?.mediaUrls && product?.mediaUrls?.length > 0
                ? product.mediaUrls[0].resizedUrls?.[2]?.url
                : testImage
            }
            alt="Product"
          />
        </div>
        <div className="ml-3 flex items-center justify-between md:w-[80%] w-[75%] product-info-wrap">
          <div className="detailwrap">
            <div className="md:text-base text-xs font-semibold text-black capitalize">
              {product?.name || "-"}
            </div>
            <div className="pricewrapper flex">
              <div className="text-black md:text-sm text-xs font-semibold">
                ₹{product?.finalPrice}
              </div>
              {product?.finalPrice < product?.productPrice ? (
                <>
                  <div className=" text-xs text-gray-dark line-through  pl-1">
                     ₹{product?.productPrice}
                  </div>
                  <div className="text-xs text-secondary uppercase">
                    ({percentageOff}% off)
                  </div>{" "}
                </>
              ) : (
                <></>
              )}
            </div>  
            <div className="md:text-sm text-xs font-medium mb-1">
              HSN : {product.hsn.value}
            </div>
            <div className="md:text-sm text-xs font-medium mb-1">
              GST {product.hsn.tax.igst}%
            </div>
            <div className="text-black md:text-xl text-xs font-semibold">
                  Total ₹{product?.cartPrice}
              </div>
          </div>  
          <div className="flex items-center btnwrap">
            <form className="max-w-[92px] wrapcount">
              <div className="flex items-center bg-white countBtn">
                <button
                  type="button"
                  id="decrement-button"
                  className="flex items-center justify-center bg-white"
                  onClick={handleDecrement}
                  disabled={isLoading}
                >
                  <span className="text-black">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="9"
                      height="4"
                      fill="none"
                      viewBox="0 0 9 4"
                    >
                      <path fill="#000" d="M.65 3.22V.1H8.9v3.12z"></path>
                    </svg>
                  </span>
                </button>
                {
                  isLoading
                    ?
                    (
                      <div className="flex items-center justify-center text-black md:text-md text-xs font-semibold px-2">
                        <Icon
                          icon="svg-spinners:ring-resize"
                          className=""
                        />
                      </div>
                    )
                    :
                    (
                      <div className="flex items-center justify-center text-black md:text-md text-xs font-semibold px-2">
                        {product?.quantity}
                      </div>
                    )
                }
                <button
                  type="button"
                  id="increment-button"
                  className="flex items-center justify-center bg-white"
                  onClick={handleIncrement}
                  disabled={isLoading}
                >
                  <span className="text-black">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      fill="none"
                      viewBox="0 0 15 15"
                    >
                      <path
                        fill="#000"
                        d="M6.26 14.25V.75h3.21v13.5zM.95 9V6h13.83v3z"
                      ></path>
                    </svg>
                  </span>
                </button>
              </div>
            </form>
            <button
                className={`${isLoading ? 'delete-btn ml-3 cursor-not-allowed' : 'delete-btn ml-3 cursor-pointer'}`}
                onClick={handleRemoveFromCart}
                disabled={isLoading}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="23"
                  fill="none"
                  viewBox="0 0 18 23"
                >
                  <path
                    fill="#CD1318"
                    d="M15.876 7.958H2.346c-.095 0-.186-.02-.28-.028l.6 12.091a2.387 2.387 0 0 0 2.387 2.262h8.117a2.39 2.39 0 0 0 2.387-2.264l.601-12.09c-.096.009-.186.03-.283.03M6.724 19.895a.796.796 0 1 1-1.592 0v-9.55a.796.796 0 0 1 1.592 0zm3.183 0a.796.796 0 1 1-1.592 0v-9.55a.796.796 0 0 1 1.592 0zm3.183 0a.796.796 0 0 1-1.591 0v-9.55a.796.796 0 1 1 1.591 0zM15.876 2.387h-2.798A2.41 2.41 0 0 0 10.69 0H7.52a2.387 2.387 0 0 0-2.388 2.387H2.347c-2.57 0-2.564 3.98 0 3.98h13.529c2.57 0 2.564-3.98 0-3.98M7.52 1.592h3.172a.807.807 0 0 1 .796.795H6.724a.796.796 0 0 1 .796-.795"
                  ></path>
                </svg>
            </button>
          </div>
        </div>
      </div>


      {/* <Paper className="w-full mt-3" elevation={1}>
        <Grid container>
          <Grid item xs={9}>
            <Box className="ml-5">
              <Box
                className=" pt-3 font-bold text-md cursor-pointer hover:text-primary"
                onClick={handleCardClick}
              >
                {product?.product || ""}
              </Box>
              <Box className="pt-1 text-sm"> Name {product?.name || "-"}</Box>

              <Box className=" pt-2 font-bold text-lg">
                ₹ {product?.finalPrice}
              </Box>
              <Box className="font-bold text-xs">MRP incl. of all taxes</Box>
              <Box className="mt-4">
                <Box className="relative inline-flex  w-28">
                  <DropdownMenu
                    toggleDropdown={toggleSizeDropdown}
                    selectedOption={selectedSize}
                    purpose={"Size"}
                  />
                  {sizeOpen && (
                    <DropdownItems
                      availableOptions={availableSizes}
                      handleOptionClick={handleSizeClick}
                    />
                  )}
                </Box>
                <Box className="relative inline-flex  w-36 ml-5">
                  <DropdownMenu
                    toggleDropdown={toggleQuantityDropdown}
                    selectedOption={selectedQuantity}
                    purpose={"Quantity"}
                  />
                  {quantityOpen && (
                    <DropdownItems
                      availableOptions={availableQuantity}
                      handleOptionClick={handleQuantityClick}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3}>
           Image <img
              className="w-[90%] mt-3 mb-3 pr-5"
              src={`${baseURL}/${product?.images?.[0]}`}
              alt="error"
            />
          </Grid>
        </Grid>
        <Divider
          className="mt-1"
          sx={{ borderBottomWidth: 1, bgcolor: "black" }}
        />
        <Box className="flex ">
          <div className="py-3 flex w-[40%] justify-center cursor-pointer">
            <button
              onClick={handleRemoveFromCart}
              className="px-3  items-center hover:text-primary"
            >
              Remove
            </button>
          </div>
          <Divider orientation="vertical" variant="middle" flexItem sx={{ borderRightWidth: 2, bgcolor: "black" }} />
          {(findWishlistItem) ? "" : (<div className="py-3 w-[60%] flex justify-center border-solid border-r-1 border-gray-light hover:text-primary cursor-pointer">
            <button onClick={handleMoveToWishlist} className="px-3 items-center">Move to Wishlist</button>
          </div>)}
        </Box>
      </Paper> */}
    </>
  );
};

export default CartCard;
