import {Grid } from "@mui/material"
import { useEffect, useState } from "react";
import { ENUM } from "../../../constant/enum";
import useDebounce from "../../../utility/useDebounce/useDebounce";
import { REGEX } from "../../../constant/regex";
const AddressForm = () => {
    const [loading, setLoading] = useState(false);

    const [firstName, setFirstName] = useState('');
    const [firstNameErr, setFirstNameErr] = useState('')

    const [lastName, setLastName] = useState('');
    const [lastNameErr, setLastNameErr] = useState('')

    const [phoneNumber, setPhoneNumber] = useState('')
    const [phoneNumberErr, setPhoneNumberErr] = useState('')

    const [pincode, setPincode] = useState('');
    const [pincodeErr, setPincodeErr] = useState('');

    const [city, setCity] = useState('');
    // const [cityErr, setCityErr] = useState('');

    const [stateName, setStateName] = useState('');
    // const [stateNameErr, setStateNameErr] = useState('');
    // const debouncedPincode = useDebounce(pincode, 500);


    const debouncedPincode = useDebounce(pincode, 500);

    useEffect(() => {
        if (firstName && !REGEX.VALID_NAME.test(firstName)) {
            setFirstNameErr('Invalid first name');
        } else {
            setFirstNameErr('');
        }
    }, [firstName]);

    useEffect(() => {
        if (lastName && !REGEX.VALID_NAME.test(lastName)) {
            setLastNameErr('Invalid last name');
        } else {
            setLastNameErr('');
        }
    }, [lastName]);

    useEffect(() => {
        if (phoneNumber && !REGEX.VALID_PHONE.test(phoneNumber)) {
            setPhoneNumberErr('Invalid phone number');
        } else {
            setPhoneNumberErr('');
        }
    }, [phoneNumber]);

    useEffect(() => {
        if (pincode && !REGEX.VALID_PINCODE.test(pincode)) {
            setPincodeErr('Invalid Pincode');
            setStateName('');
            setCity('');
        } else {
            setPincodeErr('');
        }
    }, [pincode]);

    useEffect(() => {
        if (debouncedPincode.length === 6) {
            findPincode(debouncedPincode);
        }
    }, [debouncedPincode]);

    const findPincode = async (pincode) => {
        if (pincode.length !== 6) return; // Ensure the pincode is exactly 6 digits

        setLoading(true); // Set loading to true at the start of the request
        try {
            const response = await fetch(`https://api.postalpincode.in/pincode/${pincode}`);
            const data = await response.json();
            // console.log(data);
            if (data && data[0] && data[0].PostOffice && data[0].PostOffice.length > 0) {
                const firstPostOffice = data[0].PostOffice[0];
                const state = firstPostOffice.State;
                const district = firstPostOffice.District;
                // console.log('State:', state);
                // console.log('District:', district);
                setStateName(state);
                setCity(district);
                setPincodeErr('');
            } else {
                // console.error("No PostOffice data found in the response.");
                setPincodeErr('No Matching Pincode Found');
                setStateName('');
                setCity('');
            }
        } catch (error) {
            // console.error("Error fetching pincode data:", error);
        } finally {
            setLoading(false); // Set loading to false after the request is done
        }
    };
    return (
        <>
            <Grid item xs className="px-4 py-3.5">
                <div className="flex flex-col gap-4 w-[80%]">
                    <div id="firstName" className="relative">
                        <input type="text" className={`block px-3 py-2.5 w-full text-md text-gray-dark bg-transparent rounded-sm border border-gray  appearance-none  focus:outline-none focus:ring-0 ${firstNameErr ? "focus:border-primary border-primary" : "focus:border-blue border-gray"} `} placeholder=" " value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
                        <label htmlFor="floating_outlined" className="absolute text-lg text-gray-dark tracking-wider  transform translate-x-5 -translate-y-5 scale-75 top-2 z-10 origin-[0] bg-white  px-2  ">First Name *</label>
                        <div>
                            {firstNameErr && <small className='text-xs text-red-dark '>{firstNameErr}</small>}
                        </div>
                    </div>
                    <div id="lastName" className="relative">
                        <input type="text" className={`block px-3 py-2.5 w-full text-md text-gray-dark border  bg-transparent rounded-sm  border-gray     appearance-none  focus:outline-none focus:ring-0 ${lastNameErr ? "focus:border-primary border-primary" : "focus:border-blue-light border-gray"} `} placeholder=" " value={lastName} onChange={(e) => setLastName(e.target.value)} />
                        <label htmlFor="floating_outlined" className="absolute text-lg text-gray-dark tracking-wider  transform translate-x-5 -translate-y-5 scale-75 top-2 z-10 origin-[0] bg-white  px-2  ">Last Name *</label>
                        <div>
                            {lastNameErr && <small className='text-xs text-red'>{lastNameErr}</small>}
                        </div>
                    </div>
                    <div id="phoneNumber" className="relative mb-1">
                        <span className="absolute inset-y-0 left-4 flex items-center text-md text-gray">+91</span>
                        <input
                            type="text"
                            className={`block pl-12 pr-3 py-2.5 w-full text-md text-gray-dark bg-transparent 
                                border border-gray rounded-sm   appearance-none focus:outline-none focus:ring-0 focus:border-blue-400 
                                ${phoneNumberErr ? "focus:border-primary border-primary mb-0" : "focus:border-blue-400 border-gray-light "}`}
                            placeholder=" "
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                        <label
                            htmlFor="floating_outlined"
                            className="absolute text-lg text-gray-dark tracking-wider transform translate-x-5 -translate-y-5 scale-75 top-2 z-10 origin-[0] bg-white px-2">
                            Phone Number *
                        </label>
                        <div>
                            {phoneNumberErr && <small className='absolute bottom-[-20px] text-xs text-red'>{phoneNumberErr}</small>}
                        </div>
                    </div>
                    <div id="pincode" className="relative">
                        <input type="text" className={`block px-3 py-2.5 w-full text-md text-gray-dark bg-transparent rounded-sm border border-gray appearance-none  focus:outline-none focus:ring-0${pincodeErr ? "focus:border-primary border-primary" : "focus:border-blue border-gray "}`} placeholder="" value={pincode} onChange={(e) => setPincode(e.target.value)} />
                        <label htmlFor="floating_outlined" className="absolute text-lg text-gray-dark tracking-wider  transform translate-x-5 -translate-y-5 scale-75 top-2 z-10 origin-[0] bg-white  px-2  ">PIN Code / Postal Code / ZIP Code *</label>
                        <div>
                            {pincodeErr && <small className='text-xs text-red'>{pincodeErr}</small>}
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-3">
                        <div id="city" className="relative">
                            <input type="text" className="block px-3 py-2.5 w-full text-md text-gray-dark bg-transparent rounded-sm border border-gray-light appearance-none  focus:outline-none focus:ring-0 focus:border-blue-400 peer" placeholder="" value={city} onChange={(e) => setCity(e.target.value)} />
                            <label htmlFor="floating_outlined" className="absolute text-lg text-gray-dark tracking-wider  transform translate-x-5 -translate-y-5 scale-75 top-2 z-10 origin-[0] bg-white  px-2  ">City *</label>
                        </div>
                        <div id="stateName" className="relative">
                            <input type="text" className="block px-3 py-2.5 w-full text-md text-gray-dark bg-transparent rounded-sm border border-gray-light appearance-none  focus:outline-none focus:ring-0 focus:border-blue-400 peer" placeholder="" value={stateName} onChange={(e) => setStateName(e.target.value)} />
                            <label htmlFor="floating_outlined" className="absolute text-lg text-gray-dark tracking-wider  transform translate-x-5 -translate-y-5 scale-75 top-2 z-10 origin-[0] bg-white  px-2  ">State *</label>
                        </div>
                    </div>
                    <div id="addressLine1" className="relative">
                        <input type="text" className="block px-3 py-2.5 w-full text-md text-gray-dark bg-transparent rounded-sm border border-gray-light appearance-none  focus:outline-none focus:ring-0 focus:border-blue-400 peer" placeholder="" />
                        <label htmlFor="floating_outlined" className="absolute text-lg text-gray-dark tracking-wider  transform translate-x-5 -translate-y-5 scale-75 top-2 z-10 origin-[0] bg-white  px-2  ">Flat no / Building, Street name *</label>
                    </div>
                    <div id="addressLine2" className="relative">
                        <input type="text" className="block px-3 py-2.5 w-full text-md text-gray-dark bg-transparent rounded-sm border border-gray-light appearance-none  focus:outline-none focus:ring-0 focus:border-blue-400 peer" placeholder="" />
                        <label htmlFor="floating_outlined" className="absolute text-lg text-gray-dark tracking-wider  transform translate-x-5 -translate-y-5 scale-75 top-2 z-10 origin-[0] bg-white  px-2  ">Area / Locality*</label>
                    </div>
                    <div id="addressLine2" className="relative">
                        <input type="text" className="block px-3 py-2.5 w-full text-md text-gray-dark bg-transparent rounded-sm border border-gray-light appearance-none  focus:outline-none focus:ring-0 focus:border-blue-400 peer" placeholder="" />
                        <label htmlFor="floating_outlined" className="absolute text-lg text-gray-dark tracking-wider  transform translate-x-5 -translate-y-5 scale-75 top-2 z-10 origin-[0] bg-white  px-2  ">Landmark *</label>
                    </div>
                    <div className="flex gap-3">
                        <ul className="items-center w-full text-md font-medium  bg-white border border-gray-light rounded-sm flex  ">
                            <li className="w-full border-b border-gray-light sm:border-b-0 sm:border-r ">
                                <div className="flex items-center ps-3">
                                    <input id="horizontal-list-radio-license" type="radio" value="" name="list-radio" className="w-4 h-4 text-blue-dark bg-gray-light border-gray-light  " />
                                    <label htmlFor="horizontal-list-radio-license" className="w-full py-3 ms-2 text-sm font-medium text-gray-dark tracking-wider ">Home </label>
                                </div>
                            </li>
                            <li className="w-full border-b border-gray-light sm:border-b-0 sm:border-r ">
                                <div className="flex items-center ps-3">
                                    <input id="horizontal-list-radio-license" type="radio" value="" name="list-radio" className="w-4 h-4 text-blue-dark bg-gray-light border-gray-light  " />
                                    <label htmlFor="horizontal-list-radio-license" className="w-full py-3 ms-2 text-sm font-medium text-gray-dark tracking-wider ">Office </label>
                                </div>
                            </li>
                            <li className="w-full border-b border-gray-light sm:border-b-0 sm:border-r ">
                                <div className="flex items-center ps-3">
                                    <input id="horizontal-list-radio-license" type="radio" value="" name="list-radio" className="w-4 h-4 text-blue-dark bg-gray-light border-gray-light  " />
                                    <label htmlFor="horizontal-list-radio-license" className="w-full py-3 ms-2 text-sm font-medium text-gray-dark tracking-wider ">Other </label>
                                </div>
                            </li>

                        </ul>
                    </div>
                    <button className="flex-grow border py-2 uppercase tracking-wider border-primary text-primary hover:bg-primary hover:text-white transition duration-200 ease-in-out" >Save Address</button>
                </div>
            </Grid>
        </>
    )
}
export default AddressForm