import { useLocation, useNavigate } from "react-router-dom";

const MobileFooter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isLoggedIn = Boolean(localStorage.getItem("authUser"));
  return (
    <>
      <div className="mobile-menu-bottom bg-white">
        <ul className="menu-wrap-list flex items-center justify-around px-2 py-3.5">
          <li className="menu-item home">
            <a
              className={`main-item uppercase text-[10px] ${location.pathname === "/" ? "active" : ""
                }`}
              onClick={() => navigate("/")}
            >
              <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#000000">
                <g id="SVGRepo_bgCarrier"></g>
                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M1 6V15H6V11C6 9.89543 6.89543 9 8 9C9.10457 9 10 9.89543 10 11V15H15V6L8 0L1 6Z"
                    fill="#ffffff"
                  ></path>{" "}
                </g>
              </svg>
              Home
            </a>
          </li>
          <li className="menu-item shop">
            <a className={`main-item uppercase text-[10px] ${location.pathname.includes("product") || location.pathname.includes("cart") ? "active" : ""}`} onClick={() => navigate("/products")}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                fill="none"
                viewBox="0 0 26 26"
              >
                <g fill="#000" clipPath="url(#clip0_393_2306)">
                  <path d="M24.665 10.08c-.543-.68-1.285-1.056-2.087-1.056h-1.833C20.572 4.32 17.165.559 13 .559S5.429 4.32 5.257 9.024H3.423c-.802 0-1.543.376-2.087 1.056-.685.853-.934 2.041-.675 3.184l2.199 9.69c.33 1.462 1.467 2.487 2.762 2.487h14.752c1.295 0 2.433-1.02 2.763-2.488l2.203-9.689c.26-1.142.01-2.33-.675-3.184M13.001 2.63c3.026 0 5.504 2.834 5.667 6.394H7.333c.163-3.555 2.641-6.394 5.668-6.394m10.318 10.172-2.198 9.694c-.112.498-.432.874-.742.874H5.622c-.31 0-.63-.376-.741-.874l-2.2-9.694c-.116-.513-.187-1.706.742-1.706h19.155c1 0 .858 1.193.741 1.706"></path>
                  <path d="M7.856 13.158c-.574 0-1.036.462-1.036 1.036v6.475c0 .574.462 1.036 1.036 1.036s1.036-.462 1.036-1.036v-6.475a1.034 1.034 0 0 0-1.036-1.036M12.88 13.158c-.574 0-1.036.462-1.036 1.036v6.475c0 .574.462 1.036 1.036 1.036.573 0 1.036-.462 1.036-1.036v-6.475a1.04 1.04 0 0 0-1.036-1.036M17.895 13.158c-.573 0-1.036.462-1.036 1.036v6.475c0 .574.462 1.036 1.036 1.036s1.036-.462 1.036-1.036v-6.475c0-.569-.462-1.036-1.036-1.036"></path>
                </g>
                <defs>
                  <clipPath id="clip0_393_2306">
                    <path fill="#fff" d="M0 0h26v26H0z"></path>
                  </clipPath>
                </defs>
              </svg>
              Shop
            </a>
          </li>
          <li className="menu-item order">
            <a
              className={`main-item uppercase text-[10px] ${location.pathname.includes("orders") ? "active" : ""
                }`}
              onClick={() =>
                isLoggedIn ? navigate("/profile/orders") : navigate("/login")
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="25"
                fill="none"
                viewBox="0 0 20 25"
              >
                <path
                  fill="#000"
                  stroke="#000"
                  d="M19.81 20.616 18.694 6.548a1.76 1.76 0 0 0-1.745-1.616h-2.205v-.1A4.84 4.84 0 0 0 9.91 0 4.84 4.84 0 0 0 5.08 4.832v.1H2.874c-.91 0-1.676.71-1.745 1.614L.012 20.618a4.08 4.08 0 0 0 1.072 3.078A4.08 4.08 0 0 0 4.071 25h11.68c1.13 0 2.22-.476 2.987-1.305a4.08 4.08 0 0 0 1.072-3.079M6.543 4.832a3.37 3.37 0 0 1 3.368-3.368 3.37 3.37 0 0 1 3.368 3.368v.1H6.543zM17.663 22.7a2.58 2.58 0 0 1-1.911.836H4.07a2.58 2.58 0 0 1-1.913-.836 2.58 2.58 0 0 1-.686-1.968L2.589 6.66a.287.287 0 0 1 .285-.264h2.205v1.803a.732.732 0 0 0 1.464 0V6.396h6.736v1.803a.732.732 0 0 0 1.465 0V6.396h2.205c.148 0 .273.116.285.266L18.35 20.73a2.58 2.58 0 0 1-.686 1.97"
                ></path>
                <path
                  fill="#000"

                  stroke="#000"
                  d="M13.21 12.416a.73.73 0 0 0-1.035 0l-3.27 3.269-1.257-1.257a.732.732 0 0 0-1.035 1.035l1.775 1.775a.73.73 0 0 0 1.035 0l3.787-3.787a.73.73 0 0 0 0-1.035"
                ></path>
              </svg>
              My Order
            </a>
          </li>
          <li className="menu-item account">
            <a
              className={`main-item uppercase text-[10px] ${location.pathname === "/account" ||
                location.pathname === "/login"
                ? "active"
                : ""
                }`}
              onClick={() =>
                isLoggedIn ? navigate("/account") : navigate("/login")
              }
            >
              {isLoggedIn ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 21 25"
                >
                  <path
                    fill="#000"
                    d="M9.901 11.607c1.595 0 2.975-.572 4.104-1.7 1.128-1.129 1.7-2.509 1.7-4.104 0-1.594-.572-2.975-1.7-4.103S11.495 0 9.9 0 6.926.572 5.798 1.7s-1.7 2.509-1.7 4.103c0 1.595.572 2.976 1.7 4.104s2.51 1.7 4.103 1.7M6.797 2.698c.865-.865 1.88-1.286 3.104-1.286s2.24.42 3.105 1.286c.866.866 1.287 1.882 1.287 3.105s-.421 2.24-1.287 3.105c-.866.866-1.881 1.287-3.105 1.287s-2.238-.421-3.104-1.287S5.51 7.027 5.51 5.803s.42-2.239 1.287-3.105M20.054 18.524c-.032-.47-.098-.982-.195-1.523a12 12 0 0 0-.375-1.53 7.6 7.6 0 0 0-.629-1.428 5.4 5.4 0 0 0-.949-1.237 4.2 4.2 0 0 0-1.363-.856 4.7 4.7 0 0 0-1.74-.315c-.246 0-.484.1-.943.4-.283.184-.614.398-.983.633-.316.202-.743.39-1.271.56a5 5 0 0 1-1.555.252c-.517 0-1.04-.085-1.555-.251-.528-.17-.956-.36-1.27-.56-.366-.234-.697-.447-.984-.634-.46-.3-.697-.4-.943-.4-.627 0-1.212.106-1.74.315-.53.21-.989.498-1.363.857a5.4 5.4 0 0 0-.95 1.236c-.26.46-.472.94-.628 1.428-.15.47-.277.985-.375 1.53-.097.54-.162 1.053-.195 1.523-.032.46-.048.94-.048 1.423 0 1.258.4 2.276 1.188 3.027.779.741 1.81 1.117 3.062 1.117h11.603c1.252 0 2.283-.376 3.061-1.117.789-.75 1.189-1.769 1.189-3.027 0-.485-.017-.964-.049-1.423m-2.113 3.427c-.515.49-1.198.728-2.089.728H4.25c-.89 0-1.574-.238-2.088-.727-.505-.48-.75-1.137-.75-2.005 0-.451.015-.897.045-1.325.029-.42.088-.881.176-1.371.087-.484.198-.938.33-1.35a6 6 0 0 1 .512-1.16 4 4 0 0 1 .697-.914c.242-.232.547-.422.907-.564a3.3 3.3 0 0 1 1.112-.215c.05.027.137.077.28.17.29.189.625.405.995.641.417.266.954.506 1.595.713.657.212 1.326.32 1.99.32s1.333-.108 1.99-.32c.641-.207 1.178-.447 1.596-.713.378-.242.704-.452.994-.641.143-.093.23-.143.28-.17.406.011.78.083 1.112.215q.542.215.907.564c.259.247.494.555.697.914.214.376.387.766.513 1.16.131.412.242.866.33 1.35.087.49.147.952.176 1.371.03.427.045.872.045 1.325 0 .869-.246 1.524-.75 2.004"
                  ></path>
                </svg>
              ) : (
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke=""
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      d="M13 2C10.2386 2 8 4.23858 8 7C8 7.55228 8.44772 8 9 8C9.55228 8 10 7.55228 10 7C10 5.34315 11.3431 4 13 4H17C18.6569 4 20 5.34315 20 7V17C20 18.6569 18.6569 20 17 20H13C11.3431 20 10 18.6569 10 17C10 16.4477 9.55228 16 9 16C8.44772 16 8 16.4477 8 17C8 19.7614 10.2386 22 13 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2H13Z"
                      fill="#000"
                    ></path>{" "}
                    <path
                      d="M3 11C2.44772 11 2 11.4477 2 12C2 12.5523 2.44772 13 3 13H11.2821C11.1931 13.1098 11.1078 13.2163 11.0271 13.318C10.7816 13.6277 10.5738 13.8996 10.427 14.0945C10.3536 14.1921 10.2952 14.2705 10.255 14.3251L10.2084 14.3884L10.1959 14.4055L10.1915 14.4115C10.1914 14.4116 10.191 14.4122 11 15L10.1915 14.4115C9.86687 14.8583 9.96541 15.4844 10.4122 15.809C10.859 16.1336 11.4843 16.0346 11.809 15.5879L11.8118 15.584L11.822 15.57L11.8638 15.5132C11.9007 15.4632 11.9553 15.3897 12.0247 15.2975C12.1637 15.113 12.3612 14.8546 12.5942 14.5606C13.0655 13.9663 13.6623 13.2519 14.2071 12.7071L14.9142 12L14.2071 11.2929C13.6623 10.7481 13.0655 10.0337 12.5942 9.43937C12.3612 9.14542 12.1637 8.88702 12.0247 8.7025C11.9553 8.61033 11.9007 8.53682 11.8638 8.48679L11.822 8.43002L11.8118 8.41602L11.8095 8.41281C11.4848 7.96606 10.859 7.86637 10.4122 8.19098C9.96541 8.51561 9.86636 9.14098 10.191 9.58778L11 9C10.191 9.58778 10.1909 9.58773 10.191 9.58778L10.1925 9.58985L10.1959 9.59454L10.2084 9.61162L10.255 9.67492C10.2952 9.72946 10.3536 9.80795 10.427 9.90549C10.5738 10.1004 10.7816 10.3723 11.0271 10.682C11.1078 10.7837 11.1931 10.8902 11.2821 11H3Z"
                      fill="#000"
                    ></path>{" "}
                  </g>
                </svg>
              )}
              Account
            </a>
          </li>
          <li className="menu-item help">
            <a
              className={`main-item uppercase text-[10px] ${location.pathname.includes("/help-and-support") ? "active" : ""
                }`}
              onClick={() => navigate("/help-and-support")}
            >
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                stroke=""
              >
                <g id="SVGRepo_bgCarrier"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12ZM3.00683 12C3.00683 16.9668 7.03321 20.9932 12 20.9932C16.9668 20.9932 20.9932 16.9668 20.9932 12C20.9932 7.03321 16.9668 3.00683 12 3.00683C7.03321 3.00683 3.00683 7.03321 3.00683 12Z"
                    fill="#343539"
                  ></path>{" "}
                  <path
                    d="M13.5 18C13.5 18.8284 12.8284 19.5 12 19.5C11.1716 19.5 10.5 18.8284 10.5 18C10.5 17.1716 11.1716 16.5 12 16.5C12.8284 16.5 13.5 17.1716 13.5 18Z"
                    fill="#343539"
                  ></path>{" "}
                  <path
                    d="M11 12V14C11 14 11 15 12 15C13 15 13 14 13 14V12C13 12 13.4792 11.8629 13.6629 11.7883C13.6629 11.7883 13.9969 11.6691 14.2307 11.4896C14.4646 11.3102 14.6761 11.097 14.8654 10.8503C15.0658 10.6035 15.2217 10.3175 15.333 9.99221C15.4443 9.66693 15.5 9.4038 15.5 9C15.5 8.32701 15.3497 7.63675 15.0491 7.132C14.7596 6.61604 14.3476 6.21786 13.8132 5.93745C13.2788 5.64582 12.6553 5.5 11.9427 5.5C11.4974 5.5 11.1021 5.55608 10.757 5.66825C10.4118 5.7692 10.1057 5.9094 9.83844 6.08887C9.58236 6.25712 9.36525 6.4478 9.18711 6.66091C9.02011 6.86281 8.8865 7.0591 8.78629 7.24978C8.68609 7.44046 8.61929 7.6087 8.58589 7.75452C8.51908 7.96763 8.49125 8.14149 8.50238 8.27609C8.52465 8.41069 8.59145 8.52285 8.70279 8.61258C8.81413 8.70231 8.9867 8.79765 9.22051 8.8986C9.46546 8.97712 9.65473 9.00516 9.78834 8.98273C9.93308 8.96029 10.05 8.89299 10.1391 8.78083C10.1391 8.78083 10.6138 8.10569 10.7474 7.97109C10.8922 7.82528 11.0703 7.71312 11.2819 7.6346C11.4934 7.54487 11.7328 7.5 12 7.5C12.579 7.5 13.0076 7.64021 13.286 7.92062C13.5754 8.18982 13.6629 8.41629 13.6629 8.93225C13.6629 9.27996 13.6017 9.56038 13.4792 9.77349C13.3567 9.9866 13.1953 10.1605 12.9949 10.2951C12.9949 10.2951 12.7227 10.3991 12.5 10.5C12.2885 10.5897 11.9001 10.7381 11.6997 10.8503C11.5104 10.9512 11.4043 11.0573 11.2819 11.2144C11.1594 11.3714 11 11.7308 11 12Z"
                    fill="#343539"
                  ></path>{" "}
                </g>
              </svg>
              Help
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default MobileFooter;
