import { useEffect, useState } from "react";
import CategoryIcon from "../../assets/icons/CategoryIcon.png";
import { getBrands, getCategories } from "../../api/lib/product";
import { Category } from "../../interfaces/category.interfaces";
import { useSearchParams } from "react-router-dom";
import { Brand } from "../../interfaces/brand.interfaces";

const FilterSidebar = ({ onCategorySelect }) => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [brands, setBrands] = useState<Brand[]>([]);
  const [searchParams, setSearchParams] = useSearchParams([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await getCategories({ page: 1, limit: 8 });
        const categoryList = response.data.data.list;
        setCategories(categoryList);
      } catch (error) {
        console.error("Error Fetching Categories", error);
      }
    };

    const fetchBrands = async () => {
      try {
        const response = await getBrands({ page: 1, limit: 10 });
        const brandList = response.data.data.list;
        setBrands(brandList);
      } catch (error) {
        console.error("Error Fetching Brands", error);
      }
    };

    fetchCategories();
    fetchBrands();
  }, []);

  const handleFilterClick = (
    id: string,
    name: string,
    type: "category" | "brand"
  ) => {
    // Create a new URLSearchParams instance to ensure fresh updates
    const updatedSearchParams = new URLSearchParams(searchParams);

    if (type === "category") {
      // Set the selected category ID as the sole value for "q"
      updatedSearchParams.set("q", id);
      updatedSearchParams.set("page", "1");
      onCategorySelect({ label: name, type: "category" });
    } else if (type === "brand") {
      // For brands, allow multiple selections
      const existingBrands = updatedSearchParams.get("brand")?.split(",") || [];
      const updatedBrands = existingBrands.includes(id)
        ? existingBrands.filter((brandId) => brandId !== id)
        : [...existingBrands, id];

      if (updatedBrands.length > 0) {
        updatedSearchParams.set("brand", updatedBrands.join(","));
      } else {
        updatedSearchParams.delete("brand");
      }
      updatedSearchParams.set("page", "1");
    }

    setSearchParams(updatedSearchParams);
  };

  const [activeIndex, setActiveIndex] = useState([]); // Track open accordion tabs

  // Handle accordion toggle manually
  const handleAccordionToggle = (index) => {
    setActiveIndex((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  const handleRemoveCategory = () => {
    // Create a new URLSearchParams instance to ensure fresh updates
    const updatedSearchParams = new URLSearchParams(searchParams);
    updatedSearchParams.delete("q");
    setSearchParams(updatedSearchParams);
  };

  return (
    <>
      {/* <div className="md:text-2xl text-lg color-lightblack mb-4 font-bold md:pl-0 pl-2">
        Category
      </div> */}
      <div className="rounded-3xl flex flex-col w-64 innerfilter bg-white px-2 py-4">
        <div
          className="flex flex-row items-center gap-4 py-2 px-3 cursor-pointer"
          onClick={() => handleRemoveCategory()}
        >
          <img
            className="md:w-[29px] md:h-[29px] w-[16px] h-[16px]"
            src={CategoryIcon}
            alt="Category Icon"
          />
          <span className="md:text-lg text-sm color-lightblack  font-semibold">
            All
          </span>
        </div>
        {categories.map((category, index) => (
          <div key={category._id} className="filter-item">
            <div
              className={`${searchParams.get("q") == category._id ? "active":""} p-3 parent-item cursor-pointer flex items-center md:text-lg text-xs font-semibold`}
            >
              {category?.subcategories.length > 0 && (
                <div
                  className="icon"
                  onClick={() => handleAccordionToggle(index)}
                >
                  {/* {activeIndex.includes(index) ? "🔼" : "🔽"} */}
                  {activeIndex.includes(index) ? (
                    <svg
                     xmlns="http://www.w3.org/2000/svg"
                     width="15"
                     height="9"
                     fill="none"
                     viewBox="0 0 15 9"
                    >
                     <path
                       fill="#000"
                       d="M14.48 1.059a.993.993 0 0 0-1.759-.654L7.516 6.481 2.312.405a.993.993 0 1 0-1.503 1.29l5.952 6.957a.993.993 0 0 0 1.511 0l5.958-6.957a1 1 0 0 0 .25-.636"
                     ></path>
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="9"
                      height="14"
                      fill="none"
                      viewBox="0 0 9 14"
                    >
                      <path
                        fill="#000"
                        d="M1.059 0a.993.993 0 0 0-.654 1.76l6.076 5.205-6.076 5.204a.992.992 0 0 0 .55 1.792 1 1 0 0 0 .74-.29L8.652 7.72a.993.993 0 0 0 0-1.51L1.695.25A1 1 0 0 0 1.059 0"
                      ></path>
                    </svg>
                  )}
                </div>
              )}
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  handleFilterClick(category._id, category.name, "category");
                }}
                className="p-item-name"
              >
                {category.name}
              </div>
            </div>

            {/* Accordion Content */}
            {activeIndex.includes(index) && (
              <div className="sub-item">
                {category?.subcategories?.map((subCategory) => (
                  <div
                    key={subCategory._id}
                    onClick={() =>
                      handleFilterClick(
                        subCategory._id,
                        subCategory.name,
                        "category"
                      )
                    }
                    role="button"
                    tabIndex={0}
                    className={`${searchParams.get("q") == subCategory._id ? "active":""} cursor-pointer md:pl-14 py-2 md:text-lg text-xs pl-4 hover:bg-[#F6F5FF] hover:rounded`}
                  >
                    {subCategory.name}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
        {/* {categories.map((category) => (
          <Accordion title={category.name} key={category._id} >
            {category?.subcategories?.map((subCategory) => (
              <div
                key={subCategory._id}
                onClick={() => handleFilterClick(subCategory._id,subCategory.name, "category")}
                role="button"
                tabIndex={0}
                className="cursor-pointer filter-item"
              >
                <AccordionItem label={subCategory.name} id={subCategory._id} />
              </div>
            ))}
          </Accordion>
        ))} */}
      </div>
    </>
  );
};

export default FilterSidebar;
