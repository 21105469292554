import { create } from "zustand";

interface StoreState {
  configurations: {
    contactInfo: any[];
    socialSettings: any[];
    growthSettings: any;
    brandSettings: any[];
    bestSellersSettings: any;
    testimonials: any;
  };
  brands: any[];
  setConfigurations: (configurations: StoreState['configurations']) => void;
  setBrands: (brands: any[]) => void;
}

export const useStore = create<StoreState>((set) => ({
  configurations: {
    contactInfo: [],
    socialSettings: [],
    growthSettings: {},
    brandSettings: [],
    bestSellersSettings: [],
    testimonials: []
  },
  brands: [],
  setConfigurations: (configurations) => set({ configurations }),
  setBrands: (brands) => set({ brands }),
}));
