import React from 'react'

const PrivacyPolicy = () => {
  return (
    <>
      <div className="privacy-wrapper staticpage">
        <div className="lg:mb-8 md:mb-4 md:text-2xl color-lightblack font-bold innertitle">
          <strong>Privacy Policy</strong>
        </div>

        <p className="pt-4">
          <strong>Pushkar Foods</strong> values your privacy and is committed to safeguarding your personal information. This policy outlines the types of information we collect when you use our website, mobile applications, or other online services (collectively, “Services”), and how we collect, use, maintain, protect, and disclose that information.
        </p>
        <p>
          This policy applies to information collected through our Services and electronic communications, such as email and SMS, connected with our Services. However, it does not apply to data provided to or collected by third parties, such as payment processors or social networks, that you use in conjunction with our Services. We encourage you to review the privacy policies of those third parties to understand their data handling practices.
        </p>
        <p>
          By accessing or using our Services, or by registering an account, you consent to the collection, use, disclosure, and protection of your personal data as outlined in this Privacy Policy. If you do not provide the necessary information, we may not be able to offer certain features or services.
        </p>
        <p>
          We implement appropriate security measures to protect your personal information from unauthorized access, misuse, or loss. However, since no online platform is completely secure, we encourage you to take personal precautions when sharing information over the internet.
        </p>
        <p>
          This Privacy Policy may be updated periodically. By continuing to use our Services after changes are made, you accept the revised policy. Please review this document regularly to stay informed about our privacy practices.
        </p>

        <p className="pt-6">
          <strong>The Information We Collect and How We Use It</strong>
        </p>
        <p>
          This policy explains the types of information we collect, how we collect it, and how it is used to enhance your experience with our Services. By accessing or using our website, applications, or other online platforms (collectively, “Services”), you consent to the practices outlined below. We collect different types of information, including personal data that can identify you directly or indirectly and non-personal data related to your interactions with our Services. Personal Information ("PI") includes any information that identifies you or could reasonably be linked to your identity, either on its own or when combined with other data. Anonymized or aggregated data that cannot be linked to any specific individual is not considered personal information.
        </p>

        <p className="pt-6">
          <strong>We Gather Information in the Following Ways:</strong>
        </p>
        <ul className="list-disc pl-6">
          <li>Directly from you when you provide it to us.</li>
          <li>
            Automatically as you interact with our Services, through technologies such as cookies, web beacons, and tracking tools, which collect information like IP addresses, browsing behavior, and usage patterns.
          </li>
        </ul>

        <p className="pt-6">
          <strong>Cookies Policy</strong>
        </p>
        <p>
          Our website uses cookies to enhance your browsing experience. Cookies are small text files stored on your device to collect standard internet log information and visitor behavior information. By using our site, you consent to the use of cookies. You can manage or disable cookies through your browser settings.
        </p>
        <ul className="list-disc pl-6">
          <li>
            <strong>Essential Cookies:</strong> Required for the website to function properly.
          </li>
          <li>
            <strong>Analytical Cookies:</strong> Help us understand how visitors interact with our site.
          </li>
          <li>
            <strong>Marketing Cookies:</strong> Used to deliver relevant advertisements to you.
          </li>
        </ul>

        <p className="pt-6">
          <strong>Data Security</strong>
        </p>
        <p>
          We use appropriate security measures to protect your personal information against unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet is completely secure, and we cannot guarantee absolute security.
        </p>

        <p className="pt-6">
          <strong>Your Rights</strong>
        </p>
        <ul className="list-disc pl-6">
          <li>Access and update your personal information.</li>
          <li>Request the deletion of your data, subject to applicable laws.</li>
          <li>Opt-out of marketing communications at any time.</li>
        </ul>
      </div>

    </>
  )
}

export default PrivacyPolicy
