import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getOrderStatus } from "../../api/lib/cart";

const TransactionStatus = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();

  const [timer, setTimer] = useState(10);

  useEffect(() => {
    let interval;
    const startTimer = () => {
      interval = setInterval(() => {
        setTimer((prev) => {
          if (prev === 1) {
            clearInterval(interval);
            verifyOrder();
            return 10; // Reset the timer
          }
          return prev - 1;
        });
      }, 1000);
    };

    const verifyOrder = async () => {
      try {
        const response: any = await getOrderStatus({ orderId });


        if (response?.data?.statusCode) {
          clearInterval(interval);
          navigate("/profile/orders");
        } else {
          startTimer();
        }
      } catch (error) {
        // console.log("Failed to get order status", error);
        // navigate("/profile-page/orders");
      }
    };
    verifyOrder();
    startTimer();

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [orderId, navigate]);

  return (
    <div>
      <div>Checking order status in {timer} seconds...</div>
    </div>
  );
};

export default TransactionStatus;
