import { Container } from '@mui/material'
import { Skeleton } from 'primereact/skeleton'
import React from 'react'

const OrderPageSkeleton = () => {
  return (
    <>
     <Container className="order-container py-6 min-h-[60vh]" maxWidth="lg">
            <div className="py-2"></div>
            <div className="order-card-wrap cursor-pointer">
              
                <div className="order-list-wrap flex flex-wrap gap-4">
                <Skeleton className='rounded-xl' width="32rem" height="10rem"></Skeleton>
                <Skeleton className='rounded-xl' width="32rem" height="10rem"></Skeleton>
                <Skeleton className='rounded-xl' width="32rem" height="10rem"></Skeleton>
                <Skeleton className='rounded-xl' width="32rem" height="10rem"></Skeleton>
                <Skeleton className='rounded-xl' width="32rem" height="10rem"></Skeleton>
                <Skeleton className='rounded-xl' width="32rem" height="10rem"></Skeleton>
                </div>
            </div>
          </Container>
    </>
  )
}

export default OrderPageSkeleton
