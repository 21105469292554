import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { getUserAddressList } from "../../api/lib/user";
import { Button } from "primereact/button";
import { useGeoLocation } from "../../context/GeoLocationContext";

const AddressListPopup = ({ isOpen, onHide }) => {
  const [PopupVisible, setPopupVisible] = useState(false);
  const [address, setAddress] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState<any>(null);
  const { locationData } = useGeoLocation();
  const { updateLocationData } = useGeoLocation();
  const { pincode, addressId } = locationData;

  useEffect(() => {
    getAddressList();
  }, []);

  const getAddressList = async () => {
    try {
      const response = await getUserAddressList();
      if (response.data?.data.length > 0) {
        const addressList = response.data?.data;

        const storedAddressId = localStorage.getItem("addressId");
        const storedPincode = localStorage.getItem("pincode");
        const matchingAddress = addressList.find(
          (el) => el._id === storedAddressId && el.pincode === storedPincode
        );
        if (matchingAddress) {
          setSelectedAddress(matchingAddress);
        } else {
          setSelectedAddress(addressList[0]);
        }
        // setSelectedAddress(response.data?.data[0]);
        setAddress(response.data?.data);
      }
    } catch (error) {
      console.error("Error fetching address list", error);
    }
  };

  const handleOptionAddress = (option) => {
    setSelectedAddress(option);
  };

  const selectAddress = () => {
    updateLocationData({
      pincode: selectedAddress.pincode,
      addressId: selectedAddress._id,
    });
    onHide();
    // updatePincode(selectedAddress.pincode)
  };

  const footer = (
    <div>
      <Button
        label="Submit"
        onClick={selectAddress}
        className="mt-3 mb-2 py-2 w-full flex justify-center cursor-pointer items-center text-md h-[60px] text-white bg-[#3C881F] rounded-xl tracking-wider font-bold"
      />
    </div>
  );
  return (
    <>
      <Dialog
        draggable={false}
        style={{ width: "35vw" }}
        className="bg-white p-popup m-popup"
        visible={isOpen}
        onHide={onHide}
        footer={footer}
      >
        <h1 className="mb-3">Select Address</h1>
        {address?.map((address) => (
          <div>
            <div
              className={`flex items-center mb-3 cursor-pointer ${
                selectedAddress?._id === address?._id
                  ? "selected-address"
                  : "selectaddress"
              }`}
              onClick={() => handleOptionAddress(address)}
            >
              <input
                type="radio"
                name="payment-method"
                value="Online"
                checked={selectedAddress?._id === address?._id}
                key={selectedAddress?._id}
                onChange={() => handleOptionAddress(address)}
                className="mr-2 cursor-pointer"
              />
              <div className="address-card bg-white border border-[#EFEFF1] rounded-3xl py-4 px-6 mb-4 md:w-[100%] w-full">
                <div className="card-top flex justify-between items-center border-b border-[#EFEFF1] pb-2 mb-2">
                  <div className="title font-semibold color-lightblack md:text-base text-sm">
                    {address.isDefault ? "Default Address" : "Other Address"}
                  </div>
                </div>
                <div className="address-detail">
                  <p className="text-[#6E6F72]">
                    {address?.fullAddress}
                    <br />
                    Pin - {address?.pincode}
                    <br />
                  </p>
                  <p className="color-lightblack font-semibold">
                    {address?.fullName} <br /> Mobile: 
                    {address?.mobileNumber}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Dialog>
    </>
  );
};

export default AddressListPopup;
