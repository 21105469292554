import { lazy } from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import App from "../App";
import Account from "../pages/account/account";
import AddAddresses from "../pages/address-management/add-address/AddAddress";
import AddressForm from "../pages/address-management/address-form/AddressForm";
import Addresses from "../pages/address-management/address-layout/Addresses";
import AuthPage from "../pages/auth/AuthPage";
import Login from "../pages/auth/Login";
import Logout from "../pages/auth/Logout";
import CartPage from "../pages/cart/CartPage";
import NotFound from "../pages/not-found/NotFound";
import OrderDetails from "../pages/order-details/OrderDetails";
import OrderPage from "../pages/orders/OrderPage";
import ProductCardContainer from "../pages/products/product-listing/ProductCardContainer";
import ProductPage from "../pages/products/product-page/ProductPage";
import EditProfile from "../pages/profile/edit-profile/EditProfile";
import ProfilePage from "../pages/profile/profile-page/ProfilesPage";
import About from "../pages/static/About";
import ContactUs from "../pages/static/ContactUs";
import DeliveryDetails from "../pages/static/DeliveryDetails";
import FrequentlyAskedQuestions from "../pages/static/FrequentlyAskedQuestions";
import HelpAndSupport from "../pages/static/help-and-support/HelpAndSupport";
import OurStory from "../pages/static/OurStory";
import TransactionStatus from "../pages/transaction-status/TransactionStatus";
import WishlistPage from "../pages/wishlist/WishlistPage";
import PrivateRoute from "./PrivateRoute";

const Home = lazy(() => import("../pages/home/Home"));



export const routes = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <NotFound />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path: "products/:category?",
        element: <ProductCardContainer />,
      },
      {
        path: "product/:sku",
        element: <ProductPage />
      },
      {
        path: "profile",
        element: <ProfilePage />,
        children: [
          {
            path: "orders",
            element: <OrderPage />,
          },
          {
            path: "orders/:orderId",
            element: <OrderDetails />
          },
          {
            path: "my-profile",
            element: <EditProfile />
          },
          {
            path: "addresses",
            element: <Addresses />,
            children: [
              {
                path: "",
                element: <AddAddresses />
              },
              {
                path: "address-new",
                element: <AddressForm />
              },
            ]
          },
          {
            path: "transaction/:orderId",
            element: <TransactionStatus />
          },
        ]
      },
      {
        path: "auth",
        element: <AuthPage />,
        children: [
          {
            path: "logout",
            element: <Logout />
          },  
        ]
      },
      {
        path: "wishlist",
        element: <PrivateRoute element={<WishlistPage />} />
      },
      {
        path: "cart",
        element: <CartPage />
      },
      {
        path: "about",
        element: <About />
      },
      {
        path: "our-story",
        element: <OurStory />
      },
      {
        path: "delivery",
        element: <DeliveryDetails />
      },
      {
        path: "faq",
        element: <FrequentlyAskedQuestions />
      },
      {
        path: "help-and-support",
        element: <HelpAndSupport />,
        children: [
          {
            index: true, // Default child route
            element: <Navigate to="privacy-policy" replace />,
          },
          {
            path: "privacy-policy",
            element: <HelpAndSupport />
          },
          {
            path: "terms-and-condition",
            element: <HelpAndSupport />
          },
          {
            path: "return-policy",
            element: <HelpAndSupport />
          },
          {
            path: "contact-us",
            element: <HelpAndSupport />
          },
        ]
      },
      {
        path: "account",
        element: <Account />
      },
    ],
  },


]);
