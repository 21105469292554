export const SET_BRANDS = "SET_BRANDS";
export const SET_GROWTH = "SET_GROWTH";
export const SET_CONTACT_INFO = "SET_CONTACT_INFO";
export const SET_SOCIAL_INFO = "SET_SOCIAL_INFO"; 
export const SET_BEST_SELLERS = "SET_BEST_SELLERS";
export const SET_BANNERS = "SET_BANNERS";

// Action to set brands
export const setBrands = (brands: any) => ({
  type: SET_BRANDS,
  payload: brands,
});

// Action to set growth data
export const setGrowth = (growth: any) => ({
  type: SET_GROWTH,
  payload: growth,
});

// Action to set contact info
export const setContactInfo = (contactInfo: any) => ({
    type: SET_CONTACT_INFO,
    payload: contactInfo,
  });
  
  // Action to set social info
  export const setSocialInfo = (socialInfo: any) => ({
    type: SET_SOCIAL_INFO,
    payload: socialInfo,
  });

  // Action to set best sellers products
    export const setBestSellers = (bestSellers: any) => ({
        type: SET_BEST_SELLERS,
        payload: bestSellers,
    });

 // Action to set banners
    export const setBanners = (banners: any) => ({
    type: SET_BANNERS,
    payload: banners,
  });