import { BreadCrumb } from 'primereact/breadcrumb';

const Breadcrumb = ({items, home}) => {

  return (
    <div className='breadcrumb'>
        <BreadCrumb model={items} home={home}  className='bg-transparent md:text-xl text-xs pl-0 font-[Montserrat]'/>
    </div>
  )
}

export default Breadcrumb
