import { Container, Grid } from "@mui/material";
import TabsMenu from "../profile-tab-menu/ProflieTabsMenu";
import { Outlet } from "react-router-dom";

const ProfilesPage = () => {
  return (
    <Container maxWidth="lg" className="profile-pages">
      <Grid className="flex justify-start mt-4" container>
        <TabsMenu />
        <Outlet />
      </Grid>
    </Container>
  );
};

export default ProfilesPage;
