import { Container, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { getOrderList } from "../../api/lib/cart";
import OrderCard from "../../components/order-card/OrderCard";
import OrderPageSkeleton from "../../components/shimmer-ui-components/OrderPage/OrderPageSkeleton";

const OrderPage = () => {
  const [orderList, setOrderList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getOrders();
  }, []);

  const getOrders = async () => {
    try {
      setIsLoading(true);
      const response = await getOrderList();
      setOrderList(response?.data?.data || []);
    } catch (error) {
      console.error("Failed to get order List", error);
    }
    finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading
        ?
        <OrderPageSkeleton />
        :
        (<>
          <Container className="order-container py-6 min-h-[60vh]" maxWidth="lg">
            <div className="py-2"></div>
            <div className="order-card-wrap cursor-pointer">
              {orderList?.list?.length ? (
                <div className="order-list-wrap flex flex-wrap gap-4">
                  {orderList?.list?.map((order, index) => <OrderCard order={order} key={order._id} />)}
                </div>
              ) : (
                <div className="empity-order flex flex-col items-center my-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="161"
                    height="151"
                    fill="none"
                    viewBox="0 0 161 151"
                  >
                    <path
                      fill="#F2F2F2"
                      d="M134.167 26.833 67.083 53.667 0 26.833 67.083 0z"
                    ></path>
                    <path
                      fill="#BFBFBF"
                      d="M67.083 53.666V128.8L0 101.966V26.833z"
                    ></path>
                    <path
                      fill="#8C8C8C"
                      d="M134.167 26.833v75.133L67.083 128.8V53.666z"
                    ></path>
                    <path
                      fill="#595959"
                      d="M111.036 17.576 46.261 45.35l-.644.268v24.15l-18.784-8.05v-24.15L91.61 9.821z"
                    ></path>
                    <path
                      fill="#F2F2F2"
                      d="M123.433 150.267c20.748 0 37.567-16.819 37.567-37.567 0-20.747-16.819-37.566-37.567-37.566-20.747 0-37.566 16.819-37.566 37.566s16.819 37.567 37.566 37.567"
                    ></path>
                    <path
                      fill="#595959"
                      d="M138.755 97.379a2.67 2.67 0 0 0-3.783 0L123.46 108.89l-11.511-11.511a2.673 2.673 0 0 0-3.784 0 2.673 2.673 0 0 0 0 3.783l11.512 11.512-11.512 11.511a2.674 2.674 0 0 0 0 3.784 2.64 2.64 0 0 0 1.906.778c.697 0 1.368-.269 1.905-.778l11.511-11.512 11.512 11.512a2.64 2.64 0 0 0 1.905.778c.698 0 1.368-.269 1.905-.778a2.67 2.67 0 0 0 0-3.784l-11.511-11.511 11.511-11.512a2.67 2.67 0 0 0 0-3.783z"
                    ></path>
                  </svg>
                  <p className="description italic olor-lightblack font-medium md:text-4xl text-lg">
                    There are no orders!
                  </p>
                </div>
              )}
            </div>
          </Container>
        </>)
      }
    </>
  );
};

export default OrderPage;
