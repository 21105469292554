import React, { useState, useContext } from 'react';
import { addProductToCart, cartCounts, removeProductFromCart, updateCart } from '../api/lib/cart'

const CartContext = React.createContext();

const CartContextProvider = ({ children }) => {

    const [cartItems, setCartItems] = useState([]);
    const [cartCount, setCartCount] = useState(null);
    const [cartUpdated, setCartUpdated] = useState(false);

    const findInCart = async (payload) => {

    }

    const addToCart = async (payload) => {
        try {
            const response = await addProductToCart(payload)
            const cartId = response.data.data.cartId
            localStorage.setItem('cartId', cartId);
            await getCartCount();
            setCartUpdated(true);
            return response;
        }
        catch (error) {
            console.error('Error adding to cart', error);
        }
    }

    const removeCartItem = async (payload) => {
        try {
            const response = await removeProductFromCart(payload);
            await getCartCount();
            setCartUpdated(true);
            return response
        } catch (error) {
            console.error('Error removing from cart', error);
        }
    }

    const getCartCount = async () => {
        try {
            const response = await cartCounts();
            setCartCount(response.data.data)
        } catch (error) {
            console.log("Error fetching count");
        }
    };

    const updateCartSize = async (payload) => {
        try {
            const response = await updateCart(payload);
            await getCartCount();
            setCartUpdated(true);
            return response
        } catch (error) {
            console.log("Error fetching count");
        }
    }

    // const updateCartQuantity = (item_id, quantity) => {
    //     const updatedItems = cartItems.map((item) =>
    //         item.id === item_id ? { ...item, cartQuantity: quantity } : item)
    //     setCartItems(updatedItems)
    // }

    // const updateCartSize = (item_id,size) => {
    //     const updatedItems = cartItems.map((item) =>
    //         item.id === item_id ? { ...item,cartSize:size } : item)
    //     setCartItems(updatedItems)
    // }


    const emptyCart = () => { setCartItems([]) ,setCartUpdated(true); };

    const resetCartUpdated = () => {
        setCartUpdated(false); // Reset the update status
    };


    const contextValue = {
        cartItems,
        setCartItems,
        addToCart,
        removeCartItem,
        emptyCart,
        getCartCount,
        cartCount,
        updateCartSize,
        cartUpdated,
        resetCartUpdated
        // updateCartQuantity,
        // updateCartSize
    }

    return (
        <CartContext.Provider value={contextValue}>
            {children}
        </CartContext.Provider>
    )
}

const useCart = () => useContext(CartContext);
export { useCart, CartContextProvider }; 