import { Container, Grid } from '@mui/material'
import { Skeleton } from 'primereact/skeleton'
import React from 'react'

const CartPageSkeleton = () => {
  return (
    <Container className="view-cart-container py-6 min-h-[60vh]"
      maxWidth="lg">
      <Grid className="flex justify-between cartwrapper" container>
        <Grid className="mt-1 mb-1 itemwrap" item xs={7.5}>
          <div className='flex flex-col gap-4'>
            <Skeleton className='rounded-xl' width="45rem" height="10rem"></Skeleton>
            <Skeleton className='rounded-xl' width="45rem" height="10rem"></Skeleton>
            <Skeleton className='rounded-xl' width="45rem" height="5rem"></Skeleton>
            <Skeleton className='rounded-xl' width="45rem" height="5rem"></Skeleton>
          </div>
        </Grid>
        <Grid className="mt-1 mb-1 totalwrap" item xs={4}>
            <Skeleton className='rounded-xl' width="25rem" height="20rem"></Skeleton>
        </Grid>
      </Grid>
    </Container>
  )
}

export default CartPageSkeleton
