import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Loader } from "@googlemaps/js-api-loader";
import { Button, TextField } from "@mui/material";
import {
  addUserAddress,
  checkPincodeAvailability,
  updateToStep,
} from "../../api/lib/user";
import { Icon } from "@iconify/react";
import { useGeoLocation } from "../../context/GeoLocationContext";

import { REGEX } from "../../constant/regex";

interface LocationPopupProps {
  setSteps?: (steps: number) => void;
  setVisibleLogin?: (visible: boolean) => void;
  isSignUp?: boolean;
  initialManualVisible?: boolean;
  handleHide?:any
}

export default function LocationPopup({
  setSteps,
  setVisibleLogin,
  isSignUp = true,
  initialManualVisible = false,
  handleHide
}: LocationPopupProps) {
  const [visible, setVisible] = useState(true);
  const [manualPopupVisible, setManualPopupVisible] =
    useState(initialManualVisible);
  const [manualDetails, setManualDetails] = useState({
    fullName: "",
    mobileNumber: "",
    userGst: "",
    fullAddress: "",
    pincode: "",
    floor: "",
    isLiftAvailable: "",
    landmark: "",
  });
  const [manualDetailsErr, setManualDetailsErr] = useState({ fullNameErr: "", mobileNumberErr: "" });
  const [errorMessage, setErrorMessage] = useState("");
  const [pincodeList, setPincodeList] = useState<string[]>([]);
  const floors = ["Basement", "Ground", "1st", "2nd", "3rd or above"];
  const apiKey = "AIzaSyAKLSOehljMH40Wd8mPQclidAkP7gFG3_E";
  const [geoCoordinate, setGeoCoordinate] = useState({
    latitude: 0,
    longitude: 0,
  });
  const [loading, setLoading] = useState(false);
  const { updateLocationData } = useGeoLocation();
  const validateGST = (value) => {
    // GST regex validation
    const gstRegex =
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{1}Z[0-9A-Z]{1}$/;
    return gstRegex.test(value);
  };

  useEffect(() => {
    document.body.style.overflow =
      visible || manualPopupVisible ? "hidden" : "";
    return () => {
      document.body.style.overflow = "";
    };
  }, [visible, manualPopupVisible]);

  // // Fetch initial pincode list (optional if not used)
  // useEffect(() => {
  //   const loadPincodeList = async () => {
  //     try {
  //       const response = await fetchPincodeList();
  //       const pincodes = response?.data?.data?.map((item: any) => item.pincode) || [];
  //       setPincodeList(pincodes);
  //     } catch (error) {
  //       console.error("Failed to load pincodes:", error);
  //     }
  //   };
  //   loadPincodeList();
  // }, []);

  // Load Google Maps Places API
  useEffect(() => {
    const loader = new Loader({
      apiKey: apiKey,
      libraries: ["places"],
    });

    loader.load().then(() => {
      if (window.google) {
        const autocomplete = new google.maps.places.Autocomplete(
          document.getElementById("locationInput") as HTMLInputElement,
          { componentRestrictions: { country: "in" } }
        );

        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          if (place?.formatted_address) {
            validateAndSelectLocation(
              place.formatted_address,
              extractPincode(place)
            );
          }
        });
      }
    });
  }, [apiKey]);

  const extractPincode = (place: google.maps.places.PlaceResult) => {
    const pincode = place?.address_components?.find((component) =>
      component.types.includes("postal_code")
    )?.long_name;
    return pincode || "";
  };

  const validatePincode = async (pincode: string) => {
    try {
      const response = await checkPincodeAvailability(pincode);
      return response.data.data?.isAvailable || false;
    } catch (error) {
      console.error("Error validating pincode:", error);
      return false;
    }
  };

  const validateAndSelectLocation = async (
    address: string,
    pincode: string
  ) => {
    if (pincode) {
      const isValid = await validatePincode(pincode);
      if (isValid) {
        setErrorMessage("");
        setVisible(false);
      } else {
        setErrorMessage("We are not delivering in this location.");
      }
    } else {
      setErrorMessage(
        "Pincode could not be determined for the selected location."
      );
    }
  };

  const validatePhoneNumber = () => {
    if (!REGEX.VALID_PHONE.test(manualDetails.mobileNumber)) {
      setManualDetailsErr((prevErr) => ({
        ...prevErr, mobileNumberErr: "Enter a valid mobile number"
      }))
    }
    else {
      setManualDetailsErr((prevErr) => ({
        ...prevErr,
        mobileNumberErr: "",
      }));
    }
  }
  const validateFullName = () => {
    if (!REGEX.VALID_NAME.test(manualDetails.fullName)) {
      setManualDetailsErr((prevErr) => ({
        ...prevErr, fullNameErr: "Enter a valid name"
      }))
    }
    else {
      setManualDetailsErr((prevErr) => ({
        ...prevErr,
        fullNameErr: "",
      }));
    }
  }

  const fetchCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

          const loader = new Loader({
            apiKey: apiKey,
            libraries: ["places"],
          });

          loader.load().then(() => {
            const geocoder = new google.maps.Geocoder();
            const location = new google.maps.LatLng(latitude, longitude);

            geocoder.geocode({ location }, (results, status) => {
              if (status === "OK" && results[0]) {
                const address = results[0].formatted_address;
                const pincode = results[0].address_components.find((comp) =>
                  comp.types.includes("postal_code")
                )?.long_name;

                if (pincode) {
                  // Populate geoCoordinate state
                  setGeoCoordinate({ latitude, longitude });
                  setManualDetails((prevDetails) => ({
                    ...prevDetails,
                    address, // Set the address as well, if needed
                    pincode,
                  }));
                  // Automatically validate and proceed
                  validateAndSelectLocation(address, pincode);
                  setManualPopupVisible(true);
                } else {
                  setErrorMessage(
                    "Pincode could not be determined for the current location."
                  );
                  setManualPopupVisible(true); // Open manual popup if pincode is missing
                }
              } else {
                console.error("Geocoding failed:", status);
                setErrorMessage(
                  "Failed to fetch address from location. Please enter manually."
                );
                setManualPopupVisible(true); // Open manual popup if geocoding fails
              }
            });
          });
        },
        (error) => {
          console.error("Error fetching location:", error.message);
          setErrorMessage(
            "Failed to fetch location. Please enter details manually."
          );
          setManualPopupVisible(true); // Open manual popup on location error
        },
        { enableHighAccuracy: true, timeout: 5000 }
      );
    } else {
      console.error("Geolocation not supported.");
      setErrorMessage("Geolocation is not supported on this device.");
      setManualPopupVisible(true); // Open manual popup if geolocation is unsupported
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const {
        fullAddress,
        pincode,
        floor,
        isLiftAvailable,
        landmark,
        fullName,
        mobileNumber,
        userGst,
      } = manualDetails;
      // Construct payload
      const payload = {
        isAllow: (
          !!geoCoordinate.latitude && !!geoCoordinate.longitude
        ).toString(), // true if location was fetched
        geoCoordinate:
          geoCoordinate.latitude && geoCoordinate.longitude
            ? {
                latitude: geoCoordinate.latitude,
                longitude: geoCoordinate.longitude,
              }
            : {},
        fullAddress: fullAddress || "Address not provided",
        userGst: userGst || "GST not provided",
        pincode: pincode || "Pincode not provided",
        floor: floor || "Floor not specified",
        isLift: isLiftAvailable === "Yes", // Convert to boolean
        landmark: landmark || "Landmark not specified",
        step: 3, // Fixed value for step
        ...(fullName ? { fullName } : {}),
        ...(mobileNumber ? { mobileNumber } : {}),
      };

      // Simulating the API call
      const response = isSignUp
        ? await updateToStep(payload, "step3")
        : await addUserAddress(payload);
        updateLocationData({
          pincode: response?.data?.updatedAddress?.pincode,
          addressId: response?.data?.updatedAddress?._id,
        });
      handleHide();
      setErrorMessage("");
      setManualPopupVisible(false);
      setVisible(false);
      setSteps(5); // Move to next step after successful submission
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      // setErrorMessage("Failed to submit details. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleManualSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const {
      fullAddress,
      pincode,
      floor,
      isLiftAvailable,
      landmark,
      fullName,
      userGst,
    } = manualDetails;
    if (fullAddress && pincode) {
      if (!isSignUp && !fullName?.trim()) {
        setErrorMessage("Full Name is required.");
        return;
      }
      if (!userGst?.trim()) {
        setErrorMessage("GST is required.");
        return;
      }
      // const fullAddress = `${address}, Pincode: ${pincode}, Floor: ${floor}, Lift: ${isLiftAvailable}, Landmark: ${landmark}`;
      const isValid = await validatePincode(pincode);
      if (isValid) {
        handleSubmit();
      } else {
        setErrorMessage("We are not delivering in this location.");
      }
    } else {
      setErrorMessage(
        "Please fill in the required fields: Address and Pincode."
      );
    }
  };

  function closeAllPopup() {
    setManualPopupVisible(false);
    setVisibleLogin(false);
  }

  return (
    <>
      {/* Main Location Popup */}
      <Dialog
        draggable={false}
        style={{ width: "35vw" }}
        className="bg-white p-popup m-popup"
        visible={visible}
        onHide={closeAllPopup}
      >
        <div className="location-wrap">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="150"
            height="180"
            fill="none"
            viewBox="0 0 150 180"
            className="mx-auto mb-2"
          >
            <ellipse
              cx="75"
              cy="74.634"
              fill="#FAEAEA"
              rx="75"
              ry="74.634"
            ></ellipse>
            <path
              fill="#9BD3AA"
              d="M63.46 60.631c0 19.124-22.614 31.819-28.437 52.728C28.867 92.297 6.585 79.755 6.585 60.631c0-15.703 12.733-28.436 28.436-28.436s28.435 12.733 28.435 28.436z"
            ></path>
            <path
              fill="#fff"
              d="M47.697 63.625c1.653-7.001-2.684-14.016-9.685-15.669-7.001-1.652-14.017 2.684-15.67 9.685-1.652 7.002 2.684 14.017 9.686 15.67 7.001 1.652 14.017-2.684 15.67-9.686"
            ></path>
            <path
              fill="#666363"
              d="M45.824 88.632c-.566 0-1.025.413-1.025.924l-.017 5.363c0 .511.457.93 1.02.93l.022-7.22zM45.753 97.958c-.566 0-1.026.413-1.026.924l-.016 5.363c0 .511.457.93 1.02.93l.022-7.22zM116.254 88.63c.566 0 1.023.418 1.02.93l-.046 16.05c0 .511-.46.927-1.026.924z"
            ></path>
            <path
              fill="#333"
              d="M115.751 73.506c.028-7.884.042-11.825-2.053-14.592a10.7 10.7 0 0 0-2.429-2.33c-2.888-2.013-7.017-2.024-15.273-2.04l-29.549-.066c-8.259-.02-12.386-.027-15.287 1.974a10.8 10.8 0 0 0-2.445 2.32c-2.111 2.759-2.125 6.7-2.152 14.584l-.296 86.253c-.027 7.885-.04 11.825 2.054 14.593a10.8 10.8 0 0 0 2.429 2.33c2.888 2.013 7.017 2.024 15.273 2.04l29.549.066c8.259.019 12.386.027 15.287-1.975a10.8 10.8 0 0 0 2.445-2.319c2.111-2.759 2.125-6.7 2.152-14.584z"
            ></path>
            <path
              fill="url(#paint0_linear_317_14358)"
              fillRule="evenodd"
              d="m65.974 55.193 30.156.065c4.239.008 7.329.017 9.727.268 2.38.25 3.949.725 5.202 1.586a9.9 9.9 0 0 1 2.231 2.114c.908 1.185 1.403 2.667 1.652 4.912.252 2.264.243 5.177.23 9.172l-.298 86.929c-.014 3.996-.025 6.908-.293 9.172-.265 2.243-.771 3.725-1.687 4.904a9.8 9.8 0 0 1-2.245 2.105c-1.258.856-2.831 1.324-5.213 1.562-2.404.241-5.491.235-9.73.224l-30.156-.065c-4.239-.009-7.329-.017-9.727-.268-2.38-.249-3.95-.725-5.202-1.587a10 10 0 0 1-2.231-2.113c-.908-1.185-1.403-2.667-1.655-4.912-.251-2.264-.243-5.177-.23-9.172l.296-86.93c.014-3.995.024-6.907.292-9.172.266-2.242.772-3.724 1.688-4.903a9.9 9.9 0 0 1 2.245-2.106c1.255-.856 2.83-1.323 5.212-1.561 2.404-.24 5.492-.235 9.73-.224zm48.227 3.413c2.136 2.789 2.122 6.763 2.095 14.707L116 160.242c-.027 7.944-.041 11.918-2.196 14.696a11 11 0 0 1-2.494 2.338c-2.961 2.016-7.176 2.008-15.601 1.989l-30.156-.066c-8.429-.019-12.643-.027-15.588-2.057a11 11 0 0 1-2.478-2.349c-2.136-2.789-2.122-6.763-2.095-14.707l.296-86.929c.027-7.944.04-11.918 2.196-14.696a11 11 0 0 1 2.494-2.339c2.962-2.015 7.176-2.007 15.602-1.988l30.155.066c8.429.02 12.643.027 15.588 2.056.952.657 1.789 1.45 2.478 2.35"
              clipRule="evenodd"
            ></path>
            <path
              fill="url(#paint1_linear_317_14358)"
              d="M112.659 72.775c.022-6.323.032-9.485-1.737-11.705a9 9 0 0 0-2.051-1.87c-2.439-1.617-5.926-1.622-12.897-1.639l-29.855-.065c-6.974-.014-10.46-.022-12.908 1.583a9 9 0 0 0-2.065 1.86c-1.783 2.212-1.794 5.373-1.815 11.696l-.304 87.703c-.022 6.322-.033 9.484 1.736 11.704a9 9 0 0 0 2.052 1.871c2.439 1.616 5.926 1.622 12.897 1.638l29.855.063c6.973.016 10.46.022 12.91-1.583a9 9 0 0 0 2.065-1.86c1.783-2.212 1.794-5.374 1.816-11.696l.303-87.703z"
            ></path>
            <path
              fill="#333"
              d="m74.203 60.506 6.623-.017c1.378-.002 2.5 1.007 2.502 2.256.003 1.25-1.11 2.268-2.488 2.27l-6.624.017c-1.378.002-2.5-1.007-2.502-2.256-.003-1.25 1.11-2.268 2.489-2.27M87.439 64.93c1.282 0 2.322-.98 2.322-2.19s-1.04-2.19-2.322-2.19-2.322.98-2.322 2.19 1.04 2.19 2.322 2.19"
            ></path>
            <path
              fill="#D6D4E4"
              d="m106.535 56.839-51.604.137c-3.186.008-5.762 2.382-5.754 5.3l.339 107.956c.008 2.918 2.6 5.278 5.787 5.267l51.604-.137c3.186-.008 5.762-2.382 5.754-5.3l-.339-107.956c-.009-2.918-2.601-5.278-5.787-5.267"
            ></path>
            <path
              fill="#EDECF8"
              d="m92.653 170.15-.613 5.254 1.933-.006.597-5.108zM97.316 146.753l2.138-18.309-1.898-.293-2.155 18.454zM99.66 126.689l1.537-13.176-1.855-.661-1.58 13.547zM95.195 148.357 93.02 167l1.917.137 2.176-18.632zM58.852 114.785l-5.912 14.634 1.523 1.301 6.057-14.991zM52.219 131.208l-2.809 6.952.017 5.029 4.315-10.679zM61.201 114.047l5.309-13.143-1.789-.64-5.188 12.837z"
            ></path>
            <path
              fill="#EDECF8"
              d="m97.763 126.4-18.61-2.869-.533 1.704 18.938 2.918 1.898.293 13.075 2.012-.006-1.785-12.864-1.983zM95.402 146.607l-22.945-1.783-.541 1.723 23.28 1.81 1.918.148 15.478 1.206-.005-1.766-15.27-1.19zM60.521 115.729l14.844 8.401.553-1.755-14.716-8.328-1.668-.946-10.225-5.787.005 2.073 9.539 5.399zM53.74 132.509l14.973 12.776.596-1.897-14.847-12.668-1.523-1.301-3.569-3.047.008 2.412 2.839 2.423zM67.58 76.073l.228-19.13-1.923.006-.227 19.11zM65.628 77.818l-.339 19.245 1.912.686.35-19.917zM93.924 94.871l6.172 18.4 2.046.112-6.386-19.042z"
            ></path>
            <path
              fill="#EDECF8"
              d="m67.552 77.834 17.418.112.213-1.759-17.6-.114-1.923-.014-16.458-.107.006 1.761 16.422.107zM88.31 79.084l12.992 8.165 1.414-1.247-14.166-8.898zM112.325 62.604l-8.275-5.756-3.167.008 11.45 7.963zM103.882 89.372l8.54 9.38-.008-2.757-7.143-7.846z"
            ></path>
            <path
              fill="#fff"
              d="m92.654 170.15 1.917.136 17.855 1.283a4.8 4.8 0 0 0 .235-1.507l-.006-1.654-17.718-1.275-1.917-.136-26.929-1.937 5.823-18.517.541-1.722 6.159-19.589.536-1.701 3.847-12.233c.293.039.591.063.897.063 2.04 0 3.862-.867 5.057-2.218l10.39 3.709 1.856.661 11.289 4.029-.011-3.405-22.146-7.903a5.5 5.5 0 0 0 .071-.829 5.57 5.57 0 0 0-1.055-3.241l14.537-12.793 1.39-1.222 7.096-6.246-.013-4.381-9.638 8.48-1.413 1.244-14.557 12.813a7 7 0 0 0-.947-.35l2.514-20.629.24-1.982 1.718-20.218-3.482.008-1.602 19.294-.213 1.758-2.642 21.69c-1.48.338-2.76 1.14-3.646 2.228l-11.48-4.113-1.909-.684-16.036-5.745.01 3.404 15.457 5.535 1.792.64 10.914 3.911a5 5 0 0 0-.033.593c0 1.863.94 3.528 2.407 4.619l-3.884 12.35-.552 1.756-6.055 19.258-.596 1.898-5.885 18.714-13.362-8.636.014 3.886 12.333 7.974-2.6 8.27 3.601-.011 2.3-7.31 27.536 1.98zm-8.757-67.532c1.68 0 3.044 1.25 3.044 2.787s-1.365 2.787-3.044 2.787-3.044-1.25-3.044-2.787 1.365-2.787 3.044-2.787"
            ></path>
            <path
              fill="#fff"
              stroke="#000"
              strokeLinecap="round"
              strokeMiterlimit="10"
              strokeWidth="3.06"
              d="M100.112 112.044h23.373c6.862 0 12.424-5.562 12.424-12.424s-5.562-12.424-12.424-12.424h-23.373c-6.862 0-12.424 5.563-12.424 12.424 0 6.862 5.562 12.424 12.424 12.424Z"
            ></path>
            <path
              fill="#9BD3AA"
              d="M123.453 109.642c5.536 0 10.023-4.487 10.023-10.022s-4.487-10.023-10.023-10.023-10.022 4.487-10.022 10.023 4.487 10.022 10.022 10.022"
            ></path>
            <defs>
              <linearGradient
                id="paint0_linear_317_14358"
                x1="45.464"
                x2="112.216"
                y1="50.024"
                y2="175.833"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#F2F2F2"></stop>
                <stop offset="0.48" stopColor="#F7F7F7"></stop>
                <stop offset="1" stopColor="#D2D2D2"></stop>
              </linearGradient>
              <linearGradient
                id="paint1_linear_317_14358"
                x1="80.843"
                x2="80.843"
                y1="171.706"
                y2="56.374"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#fff"></stop>
                <stop offset="1" stopColor="#fff"></stop>
              </linearGradient>
            </defs>
          </svg>
          {errorMessage && (
            <div style={{ color: "red", marginBottom: "16px" }}>
              {errorMessage}
            </div>
          )}
          <h3 className="md:text-2xl text-lg text-center font-semibold">
            Allow Location Access
          </h3>
          <p className="md:text-sm text-xs text-center font-medium">
            We will use it to deliver your order
          </p>
          <div className="flex justify-center l-button">
            <Button
              variant="contained"
              onClick={() => {
                setVisible(false);
                setManualPopupVisible(true);
              }}
              style={{
                border: "1px solid #3C881F",
                backgroundColor: "#ffffff",
                color: "#343539",
              }}
            >
              Enter Manually
            </Button>
            <Button
              variant="contained"
              onClick={fetchCurrentLocation}
              style={{
                border: "1px solid #3C881F",
                backgroundColor: "#3C881F",
                color: "#fff",
                marginLeft: "10px",
              }}
            >
              Allow
            </Button>
          </div>
        </div>
      </Dialog>

      {/* Manual Entry Popup */}
      <Dialog
        draggable={false}
        style={{ width: "35vw" }}
        className="bg-white p-popup m-popup"
        visible={manualPopupVisible}
        onHide={closeAllPopup}
      >
        <div className="m-loction">
          <h1 className="font-semibold md:text-2xl text-lg">Outlet details</h1>
          <p className="md:text-sm text-[10px] font-medium">
            This will be linked to your account and outlet
          </p>
          {!isSignUp && (
            <>
              <div className="mb-4">
                <TextField
                  label="Full Name *"
                  value={manualDetails.fullName}
                  onChange={(e) =>
                    setManualDetails({
                      ...manualDetails,
                      fullName: e.target.value,
                    })
                  }
                  onBlur={validateFullName}
                  fullWidth
                />
                {manualDetailsErr.fullNameErr && 
                <small className="flex justify-start text-xs text-red">
                {manualDetailsErr.fullNameErr}
              </small>
                }
              </div>
              <div className="mb-4">

                <TextField
                  label="Mobile *"
                  value={manualDetails.mobileNumber}
                  onChange={(e) => {
                    const { value } = e.target;
                    // Allow only digits and enforce a max length of 10
                    if (/^\d*$/.test(value) && value.length <= 10) {
                      setManualDetails({
                        ...manualDetails,
                        mobileNumber: value,
                      });
                    }
                  }}
                  onBlur={validatePhoneNumber}
                  fullWidth
                />
                {manualDetailsErr.mobileNumberErr && (
                  <small className="flex justify-start text-xs text-red">
                    {manualDetailsErr.mobileNumberErr}
                  </small>
                )}
              </div>
            </>
          )}
          <TextField
            label="Complete Address *"
            value={manualDetails.fullAddress}
            onChange={(e) =>
              setManualDetails({
                ...manualDetails,
                fullAddress: e.target.value,
              })
            }
            fullWidth
            style={{ marginBottom: "16px" }}
          />

          <TextField
            label="Pincode *"
            value={manualDetails.pincode}
            onChange={(e) =>
              setManualDetails({ ...manualDetails, pincode: e.target.value })
            }
            fullWidth
            style={{ marginBottom: "16px" }}
          />
          <TextField
            label="GST *"
            value={manualDetails.userGst}
            onChange={(e) => {
              const value = e.target.value;
              // Allow only alphanumeric characters up to 15 characters
              if (/^[a-zA-Z0-9]{0,15}$/.test(value)) {
                setManualDetails({
                  ...manualDetails,
                  userGst: value,
                });
              }
            }}
            helperText={
              manualDetails.userGst &&
              (manualDetails.userGst.length !== 15
                ? "GST must be exactly 15 characters."
                : !validateGST(manualDetails.userGst)
                ? "Invalid GST format."
                : "")
            }
            error={
              manualDetails.userGst &&
              (manualDetails.userGst.length !== 15 ||
                !validateGST(manualDetails.userGst))
            }
            fullWidth
            style={{ marginBottom: "16px" }}
          />
          <div style={{ marginBottom: "16px" }}>
            <div style={{ fontWeight: "bold", marginBottom: "8px" }}>
              Floor *
            </div>
            <div style={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
              {floors.map((floor) => (
                <div
                  key={floor}
                  onClick={() => setManualDetails({ ...manualDetails, floor })}
                  style={{
                    padding: "10px 20px",
                    border: `1px solid ${
                      manualDetails.floor === floor ? "#CA2F2E" : "#343539"
                    }`,
                    borderRadius: "4px",
                    cursor: "pointer",
                    backgroundColor:
                      manualDetails.floor === floor ? "#FAEAEA" : "white",
                  }}
                >
                  {floor}
                </div>
              ))}
            </div>
          </div>

          <div style={{ marginBottom: "16px" }}>
            <div style={{ fontWeight: "bold", marginBottom: "8px" }}>
              Is Lift Available
            </div>
            <div style={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
              {["Yes", "No"].map((option) => (
                <div
                  key={option}
                  onClick={() =>
                    setManualDetails({
                      ...manualDetails,
                      isLiftAvailable: option,
                    })
                  }
                  style={{
                    padding: "10px 20px",
                    border: `1px solid ${
                      manualDetails.isLiftAvailable === option
                        ? "#CA2F2E"
                        : "#343539"
                    }`,
                    borderRadius: "4px",
                    cursor: "pointer",
                    backgroundColor:
                      manualDetails.isLiftAvailable === option
                        ? "#FAEAEA"
                        : "white",
                  }}
                >
                  {option}
                </div>
              ))}
            </div>
          </div>

          <TextField
            label="Nearby Landmark"
            value={manualDetails.landmark}
            onChange={(e) =>
              setManualDetails({ ...manualDetails, landmark: e.target.value })
            }
            fullWidth
            style={{ marginBottom: "16px" }}
          />

          <div style={{ marginTop: "16px" }}>
            {errorMessage && (
              <div style={{ color: "red", marginBottom: "16px" }}>
                {errorMessage}
              </div>
            )}
            <button
              onClick={handleManualSubmit}
              disabled={loading}
              className={`mt-3 mb-2 py-2 w-full relative flex justify-center items-center text-md h-[60px] text-white bg-[#3C881F] rounded-xl tracking-wider font-bold ${!loading
                ? "text-white bg-[#3C881F] cursor-pointer"
                : "text-gray-dark bg-gray-light cursor-not-allowed"
                }`}
            >
              {loading && (
                <Icon
                  icon="svg-spinners:ring-resize"
                  className="absolute left-4"
                />
              )}
              <span className="mx-auto cursor-pointer">Complete</span>
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
}
