import React from 'react'

const ReturnPolicy = () => {
  return (
    <div className="returnpolicy-wrapper staticpage">
      <div className="lg:mb-8 md:mb-4 md:text-2xl color-lightblack font-bold innertitle">
        <strong>Return Policy</strong>
      </div>

      <p className="pt-4"><strong>Terms for Return and Exchange</strong></p>
      <p>Requests for return or exchange are allowed only under the following conditions:</p>
      <p>
        1. The item must have been damaged during delivery or have a valid quality issue.<br />
        2. The request must be raised within 24 hours of delivery.
      </p>

      <p className="pt-4">
        <strong>Items Not Eligible for Return or Exchange</strong><br />
        The following items are not eligible for return or exchange due to their nature:
      </p>
      <ul className="list-disc pl-6">
        <li>Pulses</li>
        <li>Vegetable oils</li>
        <li>Desi ghee</li>
        <li>Dalda oil</li>
        <li>Refined oil</li>
      </ul>
      <p>
        Note: This list is subject to updates. For confirmation regarding the return or exchange eligibility of a specific item, please contact us at
        <a href="mailto:support@pushkarfoods.com">support@pushkarfoods.com</a>.
      </p>

      <p className="pt-4">
        <strong>Conditions for Accepting Return or Exchange</strong><br />
        Merchants are responsible for inspecting the quantity, quality, and shelf life of items at the time of delivery. Once items have been delivered and accepted:
      </p>
      <ul className="list-disc pl-6">
        <li>No claims for short quantity, limited shelf life, or damage to product/packaging will be accepted unless reported immediately upon delivery.</li>
        <li>All items are deemed accepted if no issues are reported immediately upon delivery.</li>
      </ul>

      <p className="pt-4">
        <strong>How to Report a Return or Exchange</strong><br />
        To report a valid issue for return or exchange, contact our Customer Support at
        <a href="mailto:support@pushkarfoods.com">support@pushkarfoods.com</a> within 24 hours of delivery with supporting details, such as images of the product and invoice.
      </p>
    </div>

  )
}

export default ReturnPolicy
