import React from 'react'

const TermsAndCondirtions = () => {
  return (
<div className="terms-wrapper staticpage">
  <div className="lg:mb-8 md:mb-4 mb-0 md:text-2xl color-lightblack font-bold innertitle">
    <strong>Terms and Conditions</strong>
  </div>

  <p className="pt-4">
    These Terms and Conditions (“Terms”) govern the use of the Pushkar Foods platform by enrolled merchants (“Merchants”). By accessing or using our platform to purchase products (“Goods”), you agree to be bound by these Terms.
  </p>

  <div className="pt-3">
    <p><strong>1. Definitions</strong></p>
    <p><strong>Commencement Date:</strong> The date the Merchant completes the registration process and receives a confirmation from Pushkar Foods.</p>
    <p><strong>Goods:</strong> Products available on the Pushkar Foods platform, including perishable and non-perishable items.</p>
    <p><strong>Platform:</strong> The online technology platform provided by Pushkar Foods where the Merchant places orders.</p>
    <p><strong>Logistics Partner:</strong> A third-party service responsible for delivering Goods to the Merchant.</p>
    <p><strong>Perishable Goods:</strong> Items such as dairy, vegetables, bakery products, and meats with a limited shelf life.</p>
    <p><strong>Price:</strong> The amount payable for Goods as indicated on the platform or communicated at the time of order placement.</p>
  </div>

  <div className="pt-3">
    <p><strong>2. General Provisions</strong></p>
    <ol className="list-decimal ml-4">
      <li><strong>Product Listings & Orders:</strong> Pushkar Foods will display available Goods and their Prices on the platform. Merchants can browse and place orders accordingly.</li>
      <li><strong>Order Confirmation & Delivery:</strong> Orders will include an estimated delivery date. Merchants can track order status via the platform.</li>
      <li><strong>Compliance with Law:</strong> Both parties agree to comply with applicable laws. Pushkar Foods sources Goods from third-party suppliers and is not liable for manufacturing issues.</li>
      <li><strong>Manufacturer Responsibility:</strong> Quality and packaging issues are the responsibility of the original manufacturer. Pushkar Foods will facilitate communication between the Merchant and the manufacturer in case of disputes.</li>
    </ol>
  </div>

  <div className="pt-3">
    <p><strong>3. Term and Termination</strong></p>
    <p><strong>Term:</strong> This agreement begins on the Commencement Date and continues until terminated.</p>
    <p><strong>Termination:</strong> Either party may terminate with seven (7) days’ notice.</p>
    <p><strong>Immediate Termination:</strong> Pushkar Foods may suspend services or terminate the agreement if the Merchant:</p>
    <ul className="list-disc ml-6">
      <li>Fails to make payments for confirmed orders.</li>
      <li>Engages in fraudulent activities.</li>
      <li>Breaches applicable laws or these Terms.</li>
    </ul>
  </div>

  <div className="pt-3">
    <p><strong>4. Pricing and Quality</strong></p>
    <ol className="list-decimal ml-4">
      <li><strong>Price Adjustments:</strong> Prices are subject to change until the order is placed.</li>
      <li><strong>Quality Check at Delivery:</strong> Merchants must inspect Goods upon delivery. Claims for defects must be raised immediately upon receipt.</li>
      <li><strong>Expiry Management:</strong> Merchants must use perishable items within their expiry dates. Pushkar Foods is not liable for quality issues arising from delayed usage.</li>
    </ol>
  </div>

  <div className="pt-3">
    <p><strong>5. Delivery Policy</strong></p>
    <ol className="list-decimal ml-4">
      <li><strong>Delivery Timelines:</strong> Orders will be delivered within the estimated date communicated during order confirmation.</li>
      <li><strong>Acceptance of Delivery:</strong> Merchants must be present to accept deliveries. Failure to accept delivery may result in order cancellation without refund.</li>
    </ol>
  </div>

  <div className="pt-3">
    <p><strong>6. Return and Reverse Pickup Policy</strong></p>
    <p><strong>No Returns:</strong> Pushkar Foods does not accept returns unless the Goods are damaged during delivery or there are other valid quality issues.</p>
    <p>Any issues must be reported to Customer Support within 24 hours of delivery for resolution. Pushkar Foods will review such cases and may offer a replacement or refund, at its sole discretion.</p>
  </div>

  <div className="pt-3">
    <p><strong>7. Merchant Obligations</strong></p>
    <ul className="list-disc ml-6">
      <li><strong>Accurate Information:</strong> Merchants must provide up-to-date contact, address, and delivery details.</li>
      <li><strong>Timely Payment:</strong> Payments must be made promptly as per the agreed terms.</li>
      <li><strong>Elevator/Delivery Support:</strong> For deliveries beyond the second floor, Merchants must provide elevator access or assistance.</li>
      <li><strong>Order Cancellations:</strong> Cancellation requests are not guaranteed refunds after order confirmation.</li>
    </ul>
  </div>

  <div className="pt-3">
    <p><strong>8. Governing Law and Jurisdiction</strong></p>
    <p>These Terms are governed by the laws of India. Any disputes will be resolved in the courts of the city where Pushkar Foods is headquartered.</p>
  </div>

  <div className="pt-3">
    <p><strong>9. Liability and Dispute Resolution</strong></p>
    <ol className="list-decimal ml-4">
      <li><strong>Goods Liability:</strong> Pushkar Foods is not responsible for manufacturing defects or issues during transportation caused by the Merchant.</li>
      <li><strong>Indemnity:</strong> The Merchant agrees to indemnify Pushkar Foods against any losses arising from their failure to comply with these Terms.</li>
    </ol>
  </div>
</div>

  )
}

export default TermsAndCondirtions
