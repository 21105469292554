import React from "react";
import { useEffect, useRef, useState } from "react";
import { Container, Paper, Grid } from "@mui/material";
import { Icon } from "@iconify/react";
import { useLocation, useNavigate } from "react-router-dom";
import { ENUM } from "../../constant/enum";
import { useAuth } from "../../context/AuthContext";
import { CLIENT_ROUTES } from "../../constant/client_route";
import { updateToStep, userSignup } from "../../api/lib/user";
import { REGEX } from "../../constant/regex";

const SignUpPopup = ({ firebaseMetadata, setVisibleLogin, setSteps }) => {
  const errRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);

  // const userMetaData = location.state?.userMetaData || null
  // const googleUserMetaData = location.state?.googleUserMetaData || null
  // const userEmail = location.state?.userEmail || ('')

  const { setCurrentUser } = useAuth();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberErr, setPhoneNumberErr] = useState("");

  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");

  const [firstName, setFirstName] = useState("");
  const [firstNameErr, setFirstNameErr] = useState("");

  const [lastName, setLastName] = useState("");
  const [lastNameErr, setLastNameErr] = useState("");

  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState("");

  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordErr, setConfirmPasswordErr] = useState("");

  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [businessType, setBusinessType] = useState(null);

  // useEffect(() => {
  //   if (email && !REGEX.VALID_EMAIL.test(email)) {
  //     setEmailErr("Invalid email address");
  //   } else {
  //     setEmailErr("");
  //   }
  // }, [email]);

  useEffect(() => {
    if (phoneNumber && !REGEX.VALID_PHONE.test(phoneNumber)) {
      setPhoneNumberErr("Invalid phone number");
    } else {
      setPhoneNumberErr("");
    }
  }, [phoneNumber]);

  useEffect(() => {
    if (password && !REGEX.VALID_PASSWORD.test(password)) {
      setPasswordErr(
        "Password must be 8 characters and contain atleast 1 uppercase letter, 1 lowercase letter, 1 special character"
      );
    } else {
      setPasswordErr("");
    }
  }, [password]);

  useEffect(() => {
    if (confirmPassword && confirmPassword !== password) {
      setConfirmPasswordErr("Passwords do not match");
    } else {
      setConfirmPasswordErr("");
    }
  }, [confirmPassword, password]);

  // useEffect(() => {
  //   if (firstName && !REGEX.VALID_NAME.test(firstName)) {
  //     setFirstNameErr("Invalid first name");
  //   } else {
  //     setFirstNameErr("");
  //   }
  // }, [firstName]);
  const validateInputField = (type:any) => {
    if(type === 'firstName'){
      if (!REGEX.VALID_NAME.test(firstName)) {
        setFirstNameErr("Invalid first name");
      } else {
        setFirstNameErr("");
      }
    }
    if(type === 'email'){
      if (!REGEX.VALID_EMAIL.test(email)) {
        setEmailErr("Invalid email address");
      } else {
        setEmailErr("");
      }
    }
  };

  useEffect(() => {
    if (lastName && !REGEX.VALID_NAME.test(lastName)) {
      setLastNameErr("Invalid last name");
    } else {
      setLastNameErr("");
    }
  }, [lastName]);

  const toggleShowPassword = () => {
    let prevState = showPassword;
    setShowPassword(!prevState);
  };

  const handleBusinessTypeSelect = (type) => {
    setBusinessType(type); // Set selected business type
  };

  const handleSubmit = (e) => {
    setLoading(true)
    e.preventDefault();
    const fullName = firstName + " " + lastName;
    const businessTypeValue =
      businessType === "Restaurant"
        ? ENUM.BUSSINESS_TYPE.RESTURANT
        : ENUM.BUSSINESS_TYPE.RETAIL_STORE;
    try {
      updateToStep(
        {
          email: email,
          fullName: fullName,
          businessType: businessTypeValue,
          step: ENUM.SIGNUP_STEP.STEP_2,
        },
        "step2"
      )
        .then((response) => {
          console.log("response", response.data.data.updatedUser);
          localStorage.setItem(
            "authUser",
            JSON.stringify(response.data.data.updatedUser)
          );
          setCurrentUser(response.data.data.updatedUser);
          setSteps(4);
        })
        .catch((error) => {
          console.error(`Error in SignUpWithPhone`, error);
        });
    } catch (error) {
      console.error(error);
    }
    finally{
      setLoading(false)
    }
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (!emailErr && !firstNameErr && !lastNameErr && !passwordErr && !confirmPasswordErr && !phoneNumberErr) {
  //     const fullName = firstName + " " + lastName;
  //     const businessTypeValue = businessType === 'Restaurant' ? 71 : 72;
  //     // if (signUpByPhone) {
  //       try {
  //         const phoneNumberWithoutCode = firebaseMetadata?.phoneNumber?.startsWith('+91') ? firebaseMetadata?.phoneNumber?.slice(3) : firebaseMetadata?.phoneNumber;
  //         userSignup({ tempEmail: email, phone: phoneNumberWithoutCode, fullName: fullName, password: password, firebaseMetadata: firebaseMetadata, signupType: ENUM.SIGNUP_TYPE.PHONE_SIGNUP, authReqType: ENUM.AUTH_REQUEST_TYPE.SIGN_UP,businessType: businessTypeValue, })
  //           .then((response) => {
  //             setSuccess(true);
  //             userSignup({
  //               phone: phoneNumberWithoutCode,
  //               signupType: ENUM.SIGNUP_TYPE.PHONE_SIGNUP,
  //               authReqType: ENUM.AUTH_REQUEST_TYPE.CHECK_PHONE,
  //             }).then((response) => {
  //               updateStep()
  //               const accessToken = response.data.data.accessToken;
  //               localStorage.setItem('accessToken', accessToken);
  //               localStorage.setItem('authUser', JSON.stringify(response.data.data));
  //               setCurrentUser(response.data.data);
  //               c(false)
  //               // navigate(CLIENT_ROUTES.HOME);
  //               // setSteps(4);
  //               setVisibleLogin(false);
  //             }).catch((error) => {
  //               console.error(`Error Signing In With Phone`, error);
  //             })
  //           }).catch((error) => {
  //             console.error(`Error in SignUpWithPhone`, error);
  //           })
  //       }
  //       catch (error) {
  //         console.error(error)
  //       }
  //   }
  //   else {
  //     setErrMsg('Please fix the errors above');
  //   }
  // }

  return (
    <>
      <div className="wrap-detail-form">
        {/* <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p> */}
        {/* <Paper className='mt-5 flex flex-col px-3 ' elevation={2}> */}
        <div className="flex flex-col">
          <div className="md:text-3xl text-xl font-semibold color-lightblack mt-4">
            Enter Your Details
          </div>
          <p className="text-sm font-medium color-lightblack">
            This will be link to your account and outlet
          </p>
        </div>
        <div className="namefield mb-4">
          <input
            type="text"
            name="firstName"
            placeholder="Full Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            onBlur={()=>{validateInputField('firstName')}}
            autoComplete="off"
            className="block h-[55px] border-[#343539] p-2.5 w-full flex flex-col items-center outline-none rounded-xl border text-gray-dark font-semibold"
          />
          {firstNameErr && (
            <small className="text-sm text-red">{firstNameErr}</small>
          )}
        </div>
        <div className="flex items-start mb-4">
          <div className="relative w-full">
            <input
              type="text"
              className="block p-2.5 mb-2 border-[#343539] w-full flex flex-col items-center  outline-none h-[55px] rounded-xl border text-gray-dark font-semibold"
              placeholder="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onBlur={()=>{validateInputField('email')}}
              required
            />
            {emailErr && (
              <small className="text-sm text-red">
                {emailErr}
              </small>
            )}
          </div>
        </div>

        <div className="fieldwrapper">
          <p className="text-xl font-medium color-lightblack">
            Your Business Type
          </p>
          <div className="flex">
            <button
              className={`border border-[#343539] p-2.5 h-[55px] text-color-[#949399] text-sm rounded-xl min-w-[165px] mr-2 mb-3 ${
                businessType === "Restaurant" ? "is-selected" : ""
              }`}
              onClick={() => handleBusinessTypeSelect("Restaurant")}
            >
              Restaurant
            </button>
            <button
              className={`border border-[#343539] p-2.5 h-[55px] text-color-[#949399] text-sm rounded-xl min-w-[165px] mr-2 mb-3 ${
                businessType === "Retail/Kirana Store" ? "is-selected" : ""
              }`}
              onClick={() => handleBusinessTypeSelect("Retail/Kirana Store")}
            >
              Retail/Kirana Store
            </button>
          </div>

          {/* <Grid item xs={12} sm={6} className=''>
              <input type="text" name='lastName' placeholder='Last Name *' value={lastName} onChange={(e) => setLastName(e.target.value)} autoComplete='off' className='block p-2.5 w-full  text-sm font-bold text-gray-dark tracking-widest bg-white rounded border-s-0 border border-gray focus:ring-blue focus:border-blue outline-blue-light' />
              {lastNameErr && <small className='text-sm text-red'>{lastNameErr}</small>}
            </Grid> */}

          {/* <Grid item xs={12}>
              <div className="relative w-full">
                <input
                  type={showPassword ? "text" : "password"}
                  name="newPassword"
                  placeholder="Enter Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="block p-2.5 w-full text-sm font-bold text-gray-dark tracking-widest bg-white rounded-lg border border-gray focus:ring-blue focus:border-blue outline-blue-light"
                />
                {(showPassword) ?
                  <Icon
                    icon="mdi:hide-outline" width="1.2rem" height="1.2rem" className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray cursor-pointer" onClick={toggleShowPassword} />
                  :
                  <Icon icon="mdi:show-outline" width="1.2rem" height="1.2rem" className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray cursor-pointer" onClick={toggleShowPassword} />
                }
              </div>
              {passwordErr && <small className='text-sm text-red'>{passwordErr}</small>}
            </Grid>
            <Grid item xs={12}>
              <input type="password" name='confirmPassword' placeholder='Confirm Password' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} onPaste={(e) => { e.preventDefault() }} className='block p-2.5 w-full  text-sm font-bold text-gray-dark tracking-widest bg-white rounded border-s-0 border border-gray focus:ring-blue focus:border-blue outline-blue-light' />
              {confirmPasswordErr && <small className='text-sm text-red'>{confirmPasswordErr}</small>}
            </Grid> */}
        </div>
        <div className="mt-3 border-t border-[#DDDDE6] pt-4">
          <div className="flex items-start pb-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="mr-1"
              width="19"
              height="19"
              fill="none"
              viewBox="0 0 19 19"
            >
              <path
                fill="#949399"
                d="M9.5 0C4.25 0 0 4.249 0 9.5 0 14.75 4.249 19 9.5 19c5.25 0 9.5-4.249 9.5-9.5C19 4.25 14.751 0 9.5 0m.976 13.271c0 .3-.438.6-.976.6-.563 0-.963-.3-.963-.6V8.507c0-.35.4-.588.963-.588.538 0 .976.238.976.588zM9.5 6.77c-.575 0-1.025-.426-1.025-.9 0-.476.45-.889 1.025-.889.563 0 1.013.413 1.013.888s-.45.9-1.013.9"
              ></path>
            </svg>
            You will have access to a curated selection of products available
            for restaurants
          </div>
          <button
            type="submit"
            onClick={handleSubmit}
            disabled={loading && !firstNameErr && !emailErr && businessType}
            className={`mt-3 mb-2 py-2 w-full relative flex justify-center items-center text-md h-[60px] text-white bg-[#3C881F] rounded-xl tracking-wider font-bold ${
              !loading && !firstNameErr && !emailErr && businessType
                ? "text-white bg-[#3C881F] cursor-pointer"
                : "text-gray-dark bg-gray-light cursor-not-allowed"
            }`}
          >
          {loading && (
                  <Icon
                    icon="svg-spinners:ring-resize"
                    className="absolute left-4"
                  />
                )}
                <span className="mx-auto">Next</span>
          </button>
        </div>

        {/* </Paper> */}
      </div>
    </>
  );
};

export default SignUpPopup;
